export var highLightedMarketCategories = {
    futbol: [
        {
            name: 'Maç Sonucu',
            pre: '1-1',
            live: '4-4',
            outcomeCount: 3,
            priority: 0,
            labels: ['1', '0', '2'],
        },
        // {
        //   name: 'Toplam Alt/Üst',
        //   pre: '2-101',
        //   live: '4-14',
        //   outcomeCount: 2,
        //   priority: 1
        // },
        {
            name: 'Alt/Üst 2,5',
            pre: '2-101-2.5',
            live: '4-14-2.5',
            outcomeCount: 2,
            priority: 1,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'Karşılıklı Gol',
            pre: '2-89',
            live: '4-131',
            outcomeCount: 2,
            priority: 2,
            labels: ['Var', 'Yok'],
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-100',
        //   live: '4-12',
        //   outcomeCount: 3,
        //   priority: 3
        // },
        {
            name: 'Çifte Şans',
            pre: '2-92',
            live: '4-129',
            outcomeCount: 3,
            priority: 4,
            labels: ['1 ve 0', '1 ve 2', '0 ve 2'],
        },
        {
            name: 'Alt/Üst 1,5',
            pre: '2-101-1.5',
            live: '4-14-1.5',
            outcomeCount: 2,
            priority: 5,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'Alt/Üst 3,5',
            pre: '2-101-3.5',
            live: '4-14-3.5',
            outcomeCount: 2,
            priority: 6,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Alt/Üst 0.5',
            pre: '2-60-0.5',
            live: '4-63-0.5',
            outcomeCount: 2,
            priority: 7,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Alt/Üst 1.5',
            pre: '2-60-1.5',
            live: '4-63-1.5',
            outcomeCount: 2,
            priority: 8,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Sonucu',
            pre: '2-88',
            live: '4-47',
            outcomeCount: 3,
            priority: 9,
            labels: ['1', '0', '2'],
        },
        // {
        //   name: 'Toplam Gol',
        //   pre: '2-4',
        //   live: '2-4',
        //   outcomeCount: 4,
        //   priority: 10
        // }
    ],
    duello: [
        {
            name: 'Maç Sonucu',
            pre: '1-770',
            live: '4-770',
            outcomeCount: 3,
            priority: 0,
            labels: ['1', '0', '2'],
        },
        {
            name: 'Toplam Alt/Üst',
            pre: '2-772',
            live: '4-772',
            outcomeCount: 2,
            priority: 1,
            labels: ['Alt', 'Üst'],
        },
    ],
    soccer: [
        {
            name: 'Maç Sonucu',
            pre: '1-1',
            live: '4-4',
            outcomeCount: 3,
            priority: 0,
            labels: ['1', '0', '2'],
        },
        // {
        //   name: 'Toplam Alt/Üst',
        //   pre: '2-101',
        //   live: '4-14',
        //   outcomeCount: 2,
        //   priority: 1
        // },
        {
            name: 'Alt/Üst 2,5',
            pre: '2-101-2.5',
            live: '4-14-2.5',
            outcomeCount: 2,
            priority: 1,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'Karşılıklı Gol',
            pre: '2-89',
            live: '4-131',
            outcomeCount: 2,
            priority: 2,
            labels: ['Var', 'Yok'],
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-100',
        //   live: '4-12',
        //   outcomeCount: 3,
        //   priority: 3
        // },
        {
            name: 'Çifte Şans',
            pre: '2-92',
            live: '4-129',
            outcomeCount: 3,
            priority: 4,
            labels: ['1 ve 0', '1 ve 2', '0 ve 2'],
        },
        {
            name: 'Alt/Üst 1,5',
            pre: '2-101-1.5',
            live: '4-14-1.5',
            outcomeCount: 2,
            priority: 5,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'Alt/Üst 3,5',
            pre: '2-101-3.5',
            live: '4-14-3.5',
            outcomeCount: 2,
            priority: 6,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Alt/Üst 0.5',
            pre: '2-60-0.5',
            live: '4-63-0.5',
            outcomeCount: 2,
            priority: 7,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Alt/Üst 1.5',
            pre: '2-60-1.5',
            live: '4-63-1.5',
            outcomeCount: 2,
            priority: 8,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Sonucu',
            pre: '2-88',
            live: '4-47',
            outcomeCount: 3,
            priority: 9,
            labels: ['1', '0', '2'],
        },
        // {
        //   name: 'Toplam Gol',
        //   pre: '2-4',
        //   live: '2-4',
        //   outcomeCount: 4,
        //   priority: 10
        // }
    ],
    basketbol: [
        {
            name: 'Maç Sonucu',
            pre: '1-2',
            live: '4-71',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        },
        {
            name: 'Toplam Alt/Üst',
            pre: '2-114',
            live: '4-140',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'H. Maç Sonucu',
        //   pre: '2-113',
        //   live: '4-286',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: 'İlk Yarı Sonucu',
            pre: '2-621',
            live: '4-606',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'İlk Yarı Toplam Alt/Üst',
            pre: '2-116',
            live: '4-64',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        },
        // {
        //   name: 'İlk Yarı Handikaplı Sonucu',
        //   pre: '2-115',
        //   live: '2-115',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: 'İlk Çeyrek Sonucu',
            pre: '2-660',
            live: '4-673',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'Ev Sahibi Alt/Üst',
            pre: '2-619',
            live: '4-615',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        },
        {
            name: 'Deplasman Alt/Üst',
            pre: '2-620',
            live: '4-616',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        }
    ],
    basketball: [
        {
            name: 'Maç Sonucu',
            pre: '1-2',
            live: '4-71',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        },
        {
            name: 'Toplam Alt/Üst',
            pre: '2-114',
            live: '4-140',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'H. Maç Sonucu',
        //   pre: '2-113',
        //   live: '4-286',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: 'İlk Yarı Sonucu',
            pre: '2-621',
            live: '4-606',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'İlk Yarı Toplam Alt/Üst',
            pre: '2-116',
            live: '4-64',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        },
        // {
        //   name: 'İlk Yarı Handikaplı Sonucu',
        //   pre: '2-115',
        //   live: '2-115',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: 'İlk Çeyrek Sonucu',
            pre: '2-660',
            live: '4-673',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'Ev Sahibi Alt/Üst',
            pre: '2-619',
            live: '4-615',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        },
        {
            name: 'Deplasman Alt/Üst',
            pre: '2-620',
            live: '4-616',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        }
    ],
    basket: [
        {
            name: 'Maç Sonucu',
            pre: '1-2',
            live: '4-71',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        },
        {
            name: 'Toplam Alt/Üst',
            pre: '2-114',
            live: '4-140',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'H. Maç Sonucu',
        //   pre: '2-113',
        //   live: '4-286',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: 'İlk Yarı Sonucu',
            pre: '2-621',
            live: '4-606',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'İlk Yarı Toplam Alt/Üst',
            pre: '2-116',
            live: '4-64',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        },
        // {
        //   name: 'İlk Yarı Handikaplı Sonucu',
        //   pre: '2-115',
        //   live: '2-115',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: 'İlk Çeyrek Sonucu',
            pre: '2-660',
            live: '4-673',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'Ev Sahibi Alt/Üst',
            pre: '2-619',
            live: '4-615',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        },
        {
            name: 'Deplasman Alt/Üst',
            pre: '2-620',
            live: '4-616',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        }
    ],
    tenis: [
        {
            name: 'Maç Sonucu',
            pre: '1-9',
            live: '4-56',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2']
        },
        {
            name: 'Toplam Oyun Alt/Üst',
            pre: '2-168',
            live: '4-191',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-649',
        //   live: '4-613',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: '1. Oyuncu Set Kazanır',
            pre: '2-647',
            live: '4-607',
            priority: 0,
            outcomeCount: 2,
            labels: ['Evet', 'Hayır'],
        },
        {
            name: '2. Oyuncu Set Kazanır',
            pre: '2-648',
            live: '4-608',
            priority: 0,
            outcomeCount: 2,
            labels: ['Evet', 'Hayır'],
        },
        {
            name: '1. Oyuncu Alt/Üst',
            pre: '2-650',
            live: '4-622',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        {
            name: '2. Oyuncu Alt/Üst',
            pre: '2-651',
            live: '4-623',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        }
    ],
    tennis: [
        {
            name: 'Maç Sonucu',
            pre: '1-9',
            live: '4-56',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        },
        {
            name: 'Toplam Oyun Alt/Üst',
            pre: '2-168',
            live: '4-191',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-649',
        //   live: '4-613',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: '1. Oyuncu Set Kazanır',
            pre: '2-647',
            live: '4-607',
            priority: 0,
            outcomeCount: 2,
            labels: ['Evet', 'Hayır'],
        },
        {
            name: '2. Oyuncu Set Kazanır',
            pre: '2-648',
            live: '4-608',
            priority: 0,
            outcomeCount: 2,
            labels: ['Evet', 'Hayır'],
        },
        {
            name: '1. Oyuncu Alt/Üst',
            pre: '2-650',
            live: '4-622',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        {
            name: '2. Oyuncu Alt/Üst',
            pre: '2-651',
            live: '4-623',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        }
    ],
    'masa-tenisi': [
        {
            name: 'Maç Sonucu',
            pre: '2-633',
            live: '4-653',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        }
    ],
    'table-tennis': [
        {
            name: 'Maç Sonucu',
            pre: '2-633',
            live: '4-653',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        }
    ],
    'buz-hokeyi': [
        {
            name: 'Maç Sonucu',
            pre: '1-21',
            live: '4-1',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'Toplam Gol Alt/Üst',
            pre: '2-164',
            live: '4-21',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-163',
        //   live: '4-9',
        //   priority: 0,
        //   outcomeCount: 3
        // },
        {
            name: 'Çifte Şans',
            pre: '2-159',
            live: '4-128',
            priority: 0,
            outcomeCount: 3,
            labels: ['1 ve 0', '1 ve 2', '0 ve 2'],
        }
    ],
    'ice-hockey': [
        {
            name: 'Maç Sonucu',
            pre: '1-21',
            live: '4-1',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'Toplam Gol Alt/Üst',
            pre: '2-164',
            live: '4-21',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-163',
        //   live: '4-9',
        //   priority: 0,
        //   outcomeCount: 3
        // },
        {
            name: 'Çifte Şans',
            pre: '2-159',
            live: '4-128',
            priority: 0,
            outcomeCount: 3,
            labels: ['1 ve 0', '1 ve 2', '0 ve 2'],
        }
    ],
    voleybol: [
        {
            name: 'Maç Sonucu',
            pre: '1-10',
            live: '4-114',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        },
        {
            name: 'Toplam Sayı Alt/Üst',
            pre: '2-217',
            live: '4-18',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-231',
        //   live: '4-239',
        //   priority: 0,
        //   outcomeCount: 2
        // }
    ],
    hentbol: [
        {
            name: 'Maç Sonucu',
            pre: '1-16',
            live: '4-3',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'Toplam Gol Alt/Üst',
            pre: '2-191',
            live: '4-20',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-187',
        //   live: '2-187',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: 'Çifte Şans',
            pre: '2-185',
            live: '4-633',
            priority: 0,
            outcomeCount: 3,
            labels: ['1 ve 0', '1 ve 2', '0 ve 2'],
        }
    ],
    handball: [
        {
            name: 'Maç Sonucu',
            pre: '1-16',
            live: '4-3',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'Toplam Gol Alt/Üst',
            pre: '2-191',
            live: '4-20',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-187',
        //   live: '2-187',
        //   priority: 0,
        //   outcomeCount: 2
        // },
        {
            name: 'Çifte Şans',
            pre: '2-185',
            live: '4-633',
            priority: 0,
            outcomeCount: 3,
            labels: ['1 ve 0', '1 ve 2', '0 ve 2'],
        }
    ],
    snooker: [
        {
            name: 'Maç Sonucu',
            pre: '1-15',
            live: '4-600',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        },
        {
            name: 'Toplam Frame Alt/Üst',
            pre: '2-642',
            live: '4-602',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        // {
        //   name: 'Handikaplı Maç Sonucu',
        //   pre: '2-641',
        //   live: '2-641',
        //   priority: 0,
        //   outcomeCount: 2
        // }
    ],
    'motor-sporlar': [
        {
            name: 'Tümü',
            pre: '3-1',
            live: '4-600',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2']
        },
    ],
    mma: [
        {
            name: 'Maç Sonucu',
            pre: '2-637',
            live: '1-637',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        }
    ],
    'aussie-rules': [{
            name: 'Maç Sonucu',
            pre: '2-637',
            live: '1-637',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        }],
    'cycling': [{
            name: 'Maç Sonucu',
            pre: '2-637',
            live: '1-637',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        }],
    'boxing': [{
            name: 'Maç Sonucu',
            pre: '2-637',
            live: '1-637',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        }],
    'bikesmoto-gp': [{
            name: 'Maç Sonucu',
            pre: '2-637',
            live: '1-637',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        }],
    'waterpolo': [{
            name: 'Maç Sonucu',
            pre: '2-637',
            live: '1-637',
            priority: 0,
            outcomeCount: 2,
            labels: ['1', '2'],
        }],
    'esoccer': [
        {
            name: 'Maç Sonucu',
            pre: '1-1',
            live: '4-779',
            outcomeCount: 3,
            priority: 0,
            labels: ['1', '0', '2'],
        },
        {
            name: 'Alt/Üst 2,5',
            pre: '2-101-2.5',
            live: '4-780-2.5',
            outcomeCount: 2,
            priority: 1,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'Çifte Şans',
            pre: '2-92',
            live: '4-813',
            outcomeCount: 3,
            priority: 4,
            labels: ['1 ve 0', '1 ve 2', '0 ve 2'],
        },
        {
            name: 'Alt/Üst 1,5',
            pre: '2-101-1.5',
            live: '4-780-1.5',
            outcomeCount: 2,
            priority: 5,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'Alt/Üst 3,5',
            pre: '2-101-3.5',
            live: '4-780-3.5',
            outcomeCount: 2,
            priority: 6,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Alt/Üst 0.5',
            pre: '2-60-0.5',
            live: '4-784-0.5',
            outcomeCount: 2,
            priority: 7,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Alt/Üst 1.5',
            pre: '2-60-1.5',
            live: '4-784-1.5',
            outcomeCount: 2,
            priority: 8,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Sonucu',
            pre: '2-88',
            live: '4-783',
            outcomeCount: 3,
            priority: 9,
            labels: ['1', '0', '2'],
        },
    ],
    'ebasketball': [
        {
            name: 'Maç Sonucu',
            pre: '1-1',
            live: '4-786',
            outcomeCount: 2,
            priority: 0,
            labels: ['1', '2'],
        },
        {
            name: 'Toplam Alt/Üst',
            pre: '2-114',
            live: '4-787',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst'],
        },
        {
            name: 'İlk Yarı Sonucu',
            pre: '2-621',
            live: '4-785',
            priority: 0,
            outcomeCount: 3,
            labels: ['1', '0', '2'],
        },
        {
            name: 'İlk Yarı Toplam Alt/Üst',
            pre: '2-116',
            live: '4-824',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        },
        // {
        //     name: 'İlk Çeyrek Sonucu',
        //     pre: '2-660',
        //     live: '4-673',
        //     priority: 0,
        //     outcomeCount: 3,
        //     labels: ['1', '0', '2'],
        // },
        {
            name: 'Ev Sahibi Alt/Üst',
            pre: '2-619',
            live: '4-826',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        },
        {
            name: 'Deplasman Alt/Üst',
            pre: '2-620',
            live: '4-827',
            priority: 0,
            outcomeCount: 2,
            labels: ['Alt', 'Üst']
        }
    ]
};
export var handicappedMarkets = ["4-712", '2-100', '4-12', '2-113', '4-286', '2-115', '2-649', '4-613', '2-641', '2-187', '4-775', '4-807', '2-231', '4-239', '2-163', '4-9', '2-161', '2-115', '4-794', '2-829', '2-702', '4-712'];
export var newMarkets = [
    "2-718",
    "2-719",
    "2-729",
    "2-730",
    "2-821",
    "4-790",
    "4-792",
    "4-793",
    "2-735",
    "2-736",
    "2-115",
    "4-794",
    "4-796",
    "4-797",
    "4-798",
    "4-799",
    "4-795",
    "2-702",
    "4-712",
    "4-717",
    "2-707",
    "2-701",
    "2-703",
    "2-704",
    "2-705",
    "2-709",
    "2-710",
    "4-711",
    "4-713",
    "4-714",
    "4-715",
    "4-718",
    "4-743",
    "4-744",
    "4-745",
    "4-746",
    "4-719",
    "4-720",
    "4-767",
    "4-800",
    "4-801",
    "4-802",
    "2-825",
    "4-803",
    "2-826",
    "4-804",
    "2-827",
    "4-805",
    "2-828",
    "4-806",
    "2-829",
    "4-807",
    "4-808",
    "4-809",
    "4-810",
    "4-811"
];
export var rapidMarkets = [
    "4-759",
    "4-760",
    "4-761",
    "4-762",
    "4-763",
    "4-764",
    "4-765",
    "4-766"
];
export var marketCategories = {
    "futbol": [
        {
            name: "Tümü",
            betCategory: 1,
            markets: [],
            marketObjects: {}
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: [
                "2-718",
                "2-719",
                "2-729",
                "2-730",
                "2-821",
                "4-790",
                "4-792",
                "4-793",
                "2-735",
                "2-736"
            ],
            marketObjects: {}
        },
        {
            "name": "Hızlı",
            "betCategory": 3,
            "markets": [
                '4-759',
                '4-760',
                '4-761',
                '4-762',
                '4-763',
                '4-764',
                '4-765',
                '4-766'
            ],
            marketObjects: {}
        },
        {
            name: "Taraf",
            betCategory: 4,
            markets: [
                "2-727",
                "2-728",
                "2-698",
                "4-708",
                "2-723",
                "4-738",
                "2-722",
                "4-737",
                "1-1",
                "4-4",
                "2-100",
                "4-12",
                "2-7",
                "2-643",
                "2-644",
                "2-69",
                "2-72",
                "2-92",
                "4-129",
                "2-11",
                "2-42",
                "2-90",
                "4-23",
                "2-36",
                "4-321",
                "4-27"
            ],
            marketObjects: {}
        },
        {
            name: "Gol",
            betCategory: 5,
            markets: [
                "2-700",
                "2-698",
                "4-708",
                "2-724",
                "4-739",
                "2-87",
                "2-7",
                "2-101",
                "4-14",
                "2-89",
                "4-131",
                "2-4",
                "2-603",
                "4-203",
                "2-604",
                "2-207",
                "4-207",
                "2-45",
                "2-6",
                "4-199",
                "2-84",
                "2-85",
                "2-43",
                "2-44",
                "2-91",
                "4-136",
                "4-34",
                "4-207",
                "4-54",
            ],
            marketObjects: {}
        },
        {
            "name": "Yarı",
            "betCategory": 6,
            "markets": [
                "2-699",
                "4-709",
                "4-707",
                "2-724",
                "4-739",
                "2-720",
                "4-735",
                "2-88",
                "4-47",
                "2-60",
                "4-63",
                "2-6",
                "4-199",
                "2-84",
                "2-85",
                "2-77",
                "2-821",
                "4-790",
                "4-625"
            ], marketObjects: {}
        },
        {
            "name": "Korner",
            "betCategory": 7,
            "markets": [
                "4-727",
                "2-737",
                "2-56",
                "4-736",
                "4-732",
                "2-732",
                "2-731",
                "4-731",
                "2-48",
                "2-49",
                "2-52",
                "2-53",
                "2-56",
                "2-67",
                "2-68",
                "4-210",
                "4-211",
                "4-215",
                "4-217",
                "4-218",
                "4-222"
            ],
            marketObjects: {}
        },
        {
            "name": "Özel",
            "betCategory": 8,
            "markets": [
                "2-717",
                "4-740",
                "2-725",
                "2-734",
                "4-734",
                "4-733",
                "2-733",
                "2-10",
                "2-17",
                "2-86",
                "4-252",
                "4-27",
                "4-54",
                "2-801",
                "2-802",
                "2-803",
                "2-804",
                "2-800",
                "2-658",
                "4-792",
                "2-12",
                "2-661",
                "4-793",
                "2-788",
                "2-789",
                "2-790",
                "2-791",
                "2-792",
                "2-793",
                "2-794",
                "2-795",
                "2-796",
                "2-797",
                "2-798",
                "2-799",
                "2-800",
                "2-3"
            ],
            marketObjects: {}
        }
    ],
    "duello": [
        {
            name: "Tümü",
            betCategory: 1,
            markets: [],
            marketObjects: {}
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: [
                "4-728",
                "4-731",
                "4-732",
                "4-733",
                "4-734",
                "4-735",
                "4-736",
                "4-737",
                "4-738",
                "4-707",
                "4-739",
                "4-708",
                "4-709",
                "4-740",
                "4-759",
                "4-760",
                "4-761",
                "4-762",
                "4-763",
                "4-764",
                "4-765",
                "4-766",
                "2-717",
                "2-700",
                "2-727",
                "2-728",
                "2-720",
                "2-56",
                "2-722",
                "2-723",
                "2-724",
                "2-698",
                "2-699",
                "2-725",
            ],
            marketObjects: {}
        },
        {
            name: "Taraf",
            betCategory: 3,
            markets: [
                "1-1",
                "4-4",
                "2-100",
                "4-12",
                "2-7",
                "2-643",
                "2-644",
                "2-69",
                "2-72",
                "2-92",
                "4-129",
                "2-11",
                "2-42",
                "2-90",
                "4-23",
                "2-36",
                "4-321",
                "4-27"
            ],
            marketObjects: {}
        },
        {
            name: "Gol",
            betCategory: 4,
            markets: [
                "2-87",
                "2-7",
                "2-101",
                "4-14",
                "2-89",
                "4-131",
                "2-4",
                "2-603",
                "4-203",
                "2-604",
                "4-207",
                "2-45",
                "2-6",
                "4-199",
                "2-84",
                "2-85",
                "2-43",
                "2-44",
                "2-91",
                "4-136",
                "4-34",
                "4-54"
            ],
            marketObjects: {}
        },
        {
            "name": "Yarı",
            "betCategory": 5,
            "markets": [
                "2-88",
                "4-47",
                "2-60",
                "4-63",
                "2-6",
                "4-199",
                "2-84",
                "2-85",
                "2-77",
                "4-625"
            ], marketObjects: {}
        },
        {
            "name": "Korner",
            "betCategory": 6,
            "markets": [
                "2-48",
                "2-49",
                "2-52",
                "2-53",
                "2-56",
                "2-67",
                "2-68",
                "4-210",
                "4-211",
                "4-215",
                "4-217",
                "4-218",
                "4-222"
            ],
            marketObjects: {}
        },
        {
            "name": "Özel",
            "betCategory": 7,
            "markets": [
                "2-10",
                "2-17",
                "2-86",
                "4-252",
                "4-27",
                "4-54",
                "2-801",
                "2-802",
                "2-803",
                "2-804",
                "2-800",
                "2-658",
                "2-12",
                "2-661",
                "2-3"
            ],
            marketObjects: {}
        }
    ],
    "soccer": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: [
                "4-728",
                "4-731",
                "4-732",
                "4-733",
                "4-734",
                "4-735",
                "4-736",
                "4-737",
                "4-738",
                "4-707",
                "4-739",
                "4-708",
                "4-709",
                "4-740",
                "2-717",
                "2-700",
                "2-727",
                "2-728",
                "2-720",
                "2-56",
                "2-722",
                "2-723",
                "2-724",
                "2-698",
                "2-699",
                "2-725",
            ],
            marketObjects: {}
        },
        {
            "name": "Hızlı",
            "betCategory": 3,
            "markets": [
                '4-759',
                '4-760',
                '4-761',
                '4-762',
                '4-763',
                '4-764',
                '4-765',
                '4-766'
            ],
            marketObjects: {}
        },
        {
            "name": "Taraf",
            "betCategory": 4,
            "markets": [
                "1-1",
                "4-4",
                "2-100",
                "4-12",
                "2-7",
                "2-643",
                "2-644",
                "2-69",
                "2-72",
                "2-92",
                "4-129",
                "2-11",
                "2-42",
                "2-90",
                "4-23",
                "2-36",
                "4-321",
                "4-27"
            ]
        },
        {
            "name": "Gol",
            "betCategory": 5,
            "markets": [
                "2-87",
                "2-7",
                "2-101",
                "4-14",
                "2-89",
                "4-131",
                "2-4",
                "2-603",
                "4-203",
                "2-604",
                "4-207",
                "2-45",
                "2-6",
                "4-199",
                "2-84",
                "2-85",
                "2-43",
                "2-44",
                "2-91",
                "4-136",
                "4-34",
                "4-54"
            ]
        },
        {
            "name": "Yarı",
            "betCategory": 6,
            "markets": [
                "2-88",
                "4-47",
                "2-60",
                "4-63",
                "2-6",
                "4-199",
                "2-84",
                "2-85",
                "2-77",
                "4-625"
            ]
        },
        {
            "name": "Korner",
            "betCategory": 7,
            "markets": [
                "2-48",
                "2-49",
                "2-52",
                "2-53",
                "2-56",
                "2-67",
                "2-68",
                "4-210",
                "4-211",
                "4-215",
                "4-217",
                "4-218",
                "4-222"
            ]
        },
        {
            "name": "Özel",
            "betCategory": 8,
            "markets": [
                "2-10",
                "2-17",
                "2-86",
                "4-252",
                "4-27",
                "4-54",
                "2-801",
                "2-802",
                "2-803",
                "2-804",
                "2-800",
                "2-658",
                "2-12",
                "2-661",
                "2-3"
            ]
        }
    ],
    "basketbol": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: ["2-115", "4-794", "4-796", "4-797", "4-798", "4-799", "4-795"],
        },
        {
            "name": "Çeyrek",
            "betCategory": 3,
            "markets": [
                "2-660",
                "2-755",
                "4-750",
                "4-673",
                "2-756",
                "2-757",
                "4-751",
                "4-752",
                "2-749",
                "2-758",
                "2-759",
                "2-760",
                "4-742",
                "4-747",
                "4-748",
                "4-749"
            ]
        },
        {
            "name": "Taraf",
            "betCategory": 3,
            "markets": [
                "1-2",
                "2-111",
                "2-660",
                "2-105",
                "2-113",
                "2-113",
                "2-622",
                "4-71",
                "4-673",
                "2-104",
                "4-186",
                "4-286"
            ]
        },
        {
            "name": "Sayı",
            "betCategory": 4,
            "markets": [
                "2-746",
                "2-747",
                "2-107",
                "2-114",
                "2-619",
                "2-620",
                "2-622",
                "4-140",
                "4-615",
                "4-616",
                "2-748",
                "2-750",
                "2-769",
                "4-768",
                "4-796",
                "4-797",
                "4-798",
                "4-799",
                "4-795"
            ]
        },
        {
            "name": "Yarı",
            "betCategory": 5,
            "markets": [
                "2-621",
                "4-606",
                "2-106",
                "4-77",
                "2-115",
                "4-794",
                "4-64",
                "2-116",
            ]
        },
    ],
    "basketball": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: [
                "4-673",
                "4-750",
                "4-751",
                "4-752",
                "4-742",
                "4-747",
                "4-748",
                "4-749",
                "2-660",
                "2-755",
                "2-756",
                "2-757",
                "2-746",
                "2-747",
                "2-749",
                "2-758",
                "2-759",
                "2-760",
                "2-748",
                "2-750",
                "2-769",
                "4-768",
            ],
        },
        {
            "name": "Taraf",
            "betCategory": 3,
            "markets": [
                "1-2",
                "2-111",
                "2-660",
                "2-105",
                "2-113",
                "2-622",
                "4-71",
                "4-673",
                "4-286"
            ]
        },
        {
            "name": "Sayı",
            "betCategory": 4,
            "markets": [
                "2-107",
                "2-114",
                "2-619",
                "2-620",
                "2-622",
                "4-140",
                "4-615",
                "4-616"
            ]
        },
        {
            "name": "Yarı",
            "betCategory": 5,
            "markets": [
                "2-621",
                "4-606",
                "2-106",
                "4-77",
                "2-115",
                "4-64",
                "2-116"
            ]
        }
    ],
    "basket": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: [
                "4-673",
                "4-750",
                "4-751",
                "4-752",
                "4-742",
                "4-747",
                "4-748",
                "4-749",
                "2-660",
                "2-755",
                "2-756",
                "2-757",
                "2-746",
                "2-747",
                "2-749",
                "2-758",
                "2-759",
                "2-760",
                "2-748",
                "2-750",
                "2-769",
                "4-768",
            ],
        },
        {
            "name": "Taraf",
            "betCategory": 3,
            "markets": [
                "1-2",
                "2-111",
                "2-660",
                "2-105",
                "2-113",
                "2-622",
                "4-71",
                "4-673",
                "4-286"
            ]
        },
        {
            "name": "Sayı",
            "betCategory": 4,
            "markets": [
                "2-107",
                "2-114",
                "2-619",
                "2-620",
                "2-622",
                "4-140",
                "4-615",
                "4-616"
            ]
        },
        {
            "name": "Yarı",
            "betCategory": 5,
            "markets": [
                "2-621",
                "4-606",
                "2-106",
                "4-77",
                "2-115",
                "4-64",
                "2-116"
            ]
        }
    ],
    "tenis": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: [
                "2-702",
                "4-712",
                "4-717",
                "2-707",
                "2-701",
                "2-703",
                "2-704",
                "2-705",
                "2-709",
                "2-710",
                "4-711",
                "4-713",
                "4-714",
                "4-715",
                "4-718",
                "4-743",
                "4-744",
                "4-745",
                "4-746",
                "4-719",
                "4-720",
                "4-767",
            ]
        },
        {
            "name": "Taraf",
            "betCategory": 2,
            "markets": [
                "1-9",
                "4-56",
                "2-649",
                "4-613",
                "2-166",
                "4-57",
                "2-169",
                "2-647",
                "2-648",
                "2-652",
                "4-607",
                "4-608",
                "4-609"
            ]
        },
        {
            "name": "Sayı",
            "betCategory": 3,
            "markets": [
                "2-168",
                "4-191",
                "2-167",
                "4-122",
                "2-170",
                "4-124",
                "2-650",
                "4-622",
                " -651",
                "4-623"
            ]
        },
        {
            "name": "Skor",
            "betCategory": 4,
            "markets": [
                "2-171",
                "4-274",
                "2-172",
                "4-276"
            ]
        }
    ],
    "tennis": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: [
                "4-712",
                "4-717",
                "2-702",
                "2-707",
                "2-701",
                "2-703",
                "2-704",
                "2-705",
                "2-709",
                "2-710",
                "4-711",
                "4-713",
                "4-714",
                "4-715",
                "4-718",
                "4-743",
                "4-744",
                "4-745",
                "4-746",
                "4-719",
                "4-720",
                "4-767",
            ]
        },
        {
            "name": "Taraf",
            "betCategory": 2,
            "markets": [
                "1-9",
                "4-56",
                "2-649",
                "4-613",
                "2-166",
                "4-57",
                "2-169",
                "2-647",
                "2-648",
                "2-652",
                "4-607",
                "4-608",
                "4-609"
            ]
        },
        {
            "name": "Sayı",
            "betCategory": 3,
            "markets": [
                "2-168",
                "4-191",
                "2-167",
                "4-122",
                "2-170",
                "4-124",
                "2-650",
                "4-622",
                "2-651",
                "4-623"
            ]
        },
        {
            "name": "Skor",
            "betCategory": 4,
            "markets": [
                "2-171",
                "4-274",
                "2-172",
                "4-276"
            ]
        }
    ],
    "voleybol": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: ["4-800", "4-801", "4-802"]
        },
        {
            "name": "Taraf",
            "betCategory": 2,
            "markets": [
                "1-10",
                "2-231",
                "4-114",
                "4-239"
            ]
        },
        {
            "name": "Sayı",
            "betCategory": 3,
            "markets": [
                "2-217",
                "4-18",
                "4-146",
                "4-157",
                "4-167",
                "4-171",
                "4-190"
            ]
        },
        {
            "name": "Skor",
            "betCategory": 4,
            "markets": [
                "2-220",
                "4-277"
            ]
        }
    ],
    "volleyball": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            name: "Yeni",
            betCategory: 2,
            markets: [
                "4-724",
                "2-711",
                "2-712",
                "2-714",
                "2-713",
                "4-723",
                "2-715",
                "2-716",
            ]
        },
        {
            "name": "Taraf",
            "betCategory": 2,
            "markets": [
                "1-10",
                "2-231",
                "4-114",
                "4-239"
            ]
        },
        {
            "name": "Sayı",
            "betCategory": 3,
            "markets": [
                "2-217",
                "4-18"
            ]
        },
        {
            "name": "Skor",
            "betCategory": 4,
            "markets": [
                "2-220",
                "4-277"
            ]
        }
    ],
    "hentbol": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            "name": "Yeni",
            "betCategory": 2,
            "markets": ["2-825",
                "4-803",
                "2-826",
                "4-804",
                "2-827",
                "4-805",
                "2-828",
                "4-806",
                "2-829",
                "4-807",
                "4-808",
                "4-809",
                "4-810",
                "4-811"]
        },
        {
            "name": "Taraf",
            "betCategory": 3,
            "markets": [
                "1-16",
                "2-185",
                "2-187",
                "4-3",
                "4-633"
            ]
        },
        {
            "name": "Gol",
            "betCategory": 3,
            "markets": [
                "2-184",
                "2-191",
                "4-20",
                "4-133"
            ]
        }
    ],
    "handball": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            "name": "Taraf",
            "betCategory": 2,
            "markets": [
                "1-16",
                "2-185",
                "2-187",
                "4-3",
                "4-633"
            ]
        },
        {
            "name": "Gol",
            "betCategory": 3,
            "markets": [
                "2-184",
                "2-191",
                "4-20",
                "4-133"
            ]
        }
    ],
    "snooker": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            "name": "Taraf",
            "betCategory": 2,
            "markets": [
                "1-15",
                "2-641",
                "4-600"
            ]
        },
        {
            "name": "Sayı",
            "betCategory": 3,
            "markets": [
                "2-642",
                "4-602"
            ]
        }
    ],
    "masa-tenisi": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            "name": "Taraf",
            "betCategory": 2,
            "markets": [
                "1-15",
                "2-641",
                "4-600"
            ]
        },
        {
            "name": "Sayı",
            "betCategory": 3,
            "markets": [
                "2-642",
                "4-602"
            ]
        }
    ],
    "table-tennis": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "buz-hokeyi": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            "name": "Taraf",
            "betCategory": 2,
            "markets": [
                "1-21",
                "2-601",
                "2-161",
                "2-163",
                "2-126",
                "2-617",
                "2-127",
                "2-137",
                "4-1",
                "4-120",
                "4-128",
                "4-9",
                "4-37",
                "4-639"
            ]
        },
        {
            "name": "Gol",
            "betCategory": 3,
            "markets": [
                "2-164",
                "2-157",
                "2-135",
                "2-158",
                "4-619",
                "4-135",
                "4-21"
            ]
        }
    ],
    "ice-hockey": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        },
        {
            "name": "Taraf",
            "betCategory": 2,
            "markets": [
                "1-21",
                "2-601",
                "2-161",
                "2-163",
                "2-126",
                "2-617",
                "2-127",
                "2-137",
                "4-1",
                "4-120",
                "4-128",
                "4-9",
                "4-37",
                "4-639"
            ]
        },
        {
            "name": "Gol",
            "betCategory": 3,
            "markets": [
                "2-164",
                "2-157",
                "2-135",
                "2-158"
            ]
        }
    ],
    "motor-sporlar": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "moto-gp": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "mma": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "league-of-legends": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "dota-2": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "counter-strike-go": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "starcraft-2": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "overwatch": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "heartstone": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "esoccer": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
    "ebasketball": [
        {
            "name": "Tümü",
            "betCategory": 1,
            "markets": []
        }
    ],
};
export var marketNamesMappingData = {
    '1-1': {
        name: 'Maç Sonucu',
        tooltip: '90 dakika sonunda maçın nasıl biteceğinin tahmin edildiği oyun tipidir. Bu oyunda ikili tahmin yapılamaz. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '2-2': {
        name: 'Maç Skoru',
        tooltip: 'Maç skorunun tahmin edildiği oyun tipidir.'
    },
    '2-7': {
        name: 'Maç Sonucu ve Alt/Üst',
        tooltip: 'Kombine tahminin yapıldığı oyun tipidir. Aynı anda hem maç sonucunun, hem de maç için belirlenen toplam gol sayısından daha az veya daha fazla gol olacağının tahmini yapılır.'
    },
    '2-10': {
        name: 'Uzatma Olur mu',
        tooltip: 'Maçın 90 dakikasının ardından uzatmaya gidilip gidilmeyeceğinin tahmin edildiği oyun tipidir. İki tahmin vardır; Evet, Hayır'
    },
    '2-11': {
        name: 'Hangi Takım Kaç Farkla Kazanır',
        tooltip: 'Bir takımın kaç farklı skorla galip geleceğinin tahmin edildiği oyun tipidir. Yedi tahmin vardır; Ev sahibi takım 3+, Ev sahibi takım 2, Ev sahibi takım 1, Deplasman takımı 3+, Deplasman takımı 2, Deplasman takımı 1, Beraberlik'
    },
    '2-36': {
        name: 'İkinci Yarı Sonucu',
        tooltip: 'İkinci yarının nasıl biteceğinin tahmin edildiği oyun tipidir. Bu oyunda ikili tahmin yapılamaz. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '4-274': {
        name: 'Maç Skoru',
        tooltip: '2 kazanılan setin galibiyete yeteceği bir tenis maçında, maç skorunun tahmin edildiği oyun tipidir.'
    },
    '4-276': {
        name: 'Maç Skoru',
        tooltip: '3 kazanılan setin galibiyete yeteceği bir tenis maçında, maç skorunun tahmin edildiği oyun tipidir.'
    },
    '2-166': {
        name: '1.Set Kazanan',
        tooltip: 'Setin nasıl biteceğinin tahmin edildiği oyun tipidir. İki tahmin vardır; Ev sahibi 1. Oyuncu (1), Deplasman 2. Oyuncu (2)'
    },
    '2-115': {
        name: 'İlk Yarı Handikap',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen sayı kadar maça önde başlamış sayılır (sayı avansı) ve bu sayı hesaba katılarak ilk yarıyı (2. çeyrek) hangi takımın galip bitireceğinin tahmin edildiği oyun tipidir.'
    },
    '4-794': {
        name: 'İlk Yarı Handikap',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen sayı kadar maça önde başlamış sayılır (sayı avansı) ve bu sayı hesaba katılarak ilk yarıyı (2. çeyrek) hangi takımın galip bitireceğinin tahmin edildiği oyun tipidir.'
    },
    '2-169': {
        name: '2.Set Kazanan',
        tooltip: 'Setin nasıl biteceğinin tahmin edildiği oyun tipidir. İki tahmin vardır; Ev sahibi 1. Oyuncu (1), Deplasman 2. Oyuncu (2)'
    },
    '2-167': {
        name: 'Toplam Sayı',
        tooltip: '2 kazanılan setin galibiyete yeteceği bir tenis maçında, her iki oyuncunun maç boyunca kazanacakları toplam set sayısına göre tahmin yapılır. İki tahmin vardır; 2 Set, 3 Set.'
    },
    '4-140': {
        name: 'Toplam Sayı Alt/Üst (Uzatmalar Dahil)',
        tooltip: 'Uzatmalar dahil olmak üzere, maçta atılacak toplam sayının programda belirtilen sayı bareminden daha az veya daha fazla olacağının tahmin edildiği oyun tipidir.'
    },
    '2-642': {
        name: 'Toplam Frame Alt/Üst',
        tooltip: 'Her iki takımın maç boyunca kazanacakları toplam frame\'in programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '2-172': {
        name: 'Maç Skoru',
        tooltip: '3 kazanılan setin galibiyete yeteceği bir tenis maçında, maç skorunun tahmin edildiği oyun tipidir.'
    },
    '4-57': {
        name: 'Hangi Oyuncu Seti Kazanır',
        tooltip: 'Setin nasıl biteceğinin tahmin edildiği oyun tipidir. İki tahmin vardır; Ev sahibi 1. Oyuncu (1), Deplasman 2. Oyuncu (2)'
    },
    '4-122': {
        name: 'Toplam Sayı',
        tooltip: '2 kazanılan setin galibiyete yeteceği bir tenis maçında, her iki oyuncunun maç boyunca kazanacakları toplam set sayısına göre tahmin yapılır. İki tahmin vardır; 2 Set, 3 Set.'
    },
    '4-321': {
        name: 'İkinci Yarı Sonucu',
        tooltip: 'İkinci yarının nasıl biteceğinin tahmin edildiği oyun tipidir. Bu oyunda ikili tahmin yapılamaz. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '2-647': {
        name: '1. Oyuncu Set Kazanır',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '4-623': {
        name: '2. Oyuncu Toplam Oyun Alt/Üst',
        tooltip: '2. Oyuncunun (deplasman) kazanacağı toplam oyun sayısına göre tahmin yapılır. Handikaplar buçukludur ve bu nedenle beraberlik seçeneği bulunmamaktadır.'
    },
    '4-256': {
        name: 'Maç Sonucu ve Alt/Üst',
        tooltip: 'Kombine tahminin yapıldığı oyun tipidir. Aynı anda hem maç sonucunun, hem de maç için belirlenen toplam gol sayısından daha az veya daha fazla gol olacağının tahmini yapılır.'
    },
    '2-648': {
        name: '2. Oyuncu Set Kazanır',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '2-649': {
        name: 'Handikaplı Maç Sonucu (Oyun Handikapı)',
        tooltip: 'Belirtilen oyuncuya verilen oyun avansıdır. Oyun avansı verilen takım maça verilen oyun sayısı kadar önde başlar. İki ihtimali bulunan bu oyunda (01) Ev Sahibi, (02) Deplasman şeklinde kodlanmaktadır.'
    },
    '2-650': {
        name: '1. Oyuncu Toplam Oyun Alt/Üst',
        tooltip: '1. Oyuncunun (ev sahibi) kazanacağı toplam oyun sayısına göre tahmin yapılır. Handikaplar buçukludur ve bu nedenle beraberlik seçeneği bulunmamaktadır.'
    },
    '2-651': {
        name: '2. Oyuncu Toplam Oyun Alt/Üst',
        tooltip: '2. Oyuncunun (deplasman) kazanacağı toplam oyun sayısına göre tahmin yapılır. Handikaplar buçukludur ve bu nedenle beraberlik seçeneği bulunmamaktadır.'
    },
    '2-652': {
        name: 'Tie Break Olur mu?',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '2-654': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: 'Handikap seçeneği bulunan oyuncu, belirlenen set sayısı kadar maça önde başlamış sayılır (set avansı) ve bu sayı hesaba katılarak maçı hangi takımın galip bitireceğinin tahmin edildiği oyun tipidir.'
    },
    '2-42': {
        name: 'Maç Sonucuna Nasıl Ulaşılır',
        tooltip: 'Altı farklı tahmin vardır; Ev sahibi takım-normal süre, Ev sahibi takım-uzatmalar, Ev sahibi takım-penaltılar, Deplasman takımı-normal süre, Deplasman takımı-uzatmalar, Deplasman takımı-penaltılar'
    },
    '2-69': {
        name: 'Ev Sahibi Takım Her İki Yarıyı da Kazanır mı',
        tooltip: 'Ev sahibi takımın her iki yarıda da rakibinden daha fazla gol atması gerekir. İki tahmin vardır; Evet, Hayır'
    },
    '2-72': {
        name: 'Deplasman Takımı Her İki Yarıyı da Kazanır mı',
        tooltip: 'Deplasman takımının her iki yarıda da rakibinden daha fazla gol atması gerekir. İki tahmin vardır; Evet, Hayır'
    },
    '2-77': {
        name: 'İlk Yarı Çifte Şans',
        tooltip: 'İlk Yarı sonucuna ilişkin bu oyunda, bir tahmin iki farklı sonucu kapsar. Üç tahmin vardır; 1-0 (Ev sahibi takım galibiyeti ve Beraberlik), 0-2 (Beraberlik ve Deplasman takım galibiyeti) ve 1-2 (Ev sahibi ve Deplasman takım galibiyeti)'
    },
    '2-88': {
        name: 'İlk Yarı Sonucu',
        tooltip: 'İlk yarının nasıl biteceğinin tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '2-90': {
        name: 'İlk Yarı / Maç Sonucu',
        tooltip: 'Maçın ilk yarısının sonucu ile 90 dakika sonucunun kombine tahmin edildiği oyun tipidir. Dokuz tahmin vardır; 1/1, 1/0, 1/2, 0/1, 0/2, 0/0, 2/1, 2/0, 2/2'
    },
    '2-117': {
        name: 'Alt/Üst',
        tooltip: 'Her iki takımın maç boyunca atacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-124': {
        name: 'Toplam Sayı',
        tooltip: '3 kazanılan setin galibiyete yeteceği bir tenis maçında, her iki oyuncunun maç boyunca kazanacakları toplam set sayısına göre tahmin yapılır. Üç tahmin vardır; 3 Set, 4 Set, 5 Set.'
    },
    '2-168': {
        name: 'Toplam Oyun Alt/Üst',
        tooltip: 'Her iki oyuncunun maç boyunca kazanacakları toplam oyunun programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '2-170': {
        name: 'Toplam Sayı',
        tooltip: '3 kazanılan setin galibiyete yeteceği bir tenis maçında, her iki oyuncunun maç boyunca kazanacakları toplam set sayısına göre tahmin yapılır. Üç tahmin vardır; 3 Set, 4 Set, 5 Set.'
    },
    '2-171': {
        name: 'Maç Skoru',
        tooltip: '2 kazanılan setin galibiyete yeteceği bir tenis maçında, maç skorunun tahmin edildiği oyun tipidir.'
    },
    '2-92': {
        name: 'Çifte Şans',
        tooltip: 'Maç sonucuna ilişkin bu oyunda, bir tahmin iki farklı sonucu kapsar. Üç tahmin vardır; 1-0 (Ev sahibi takım galibiyeti ve Beraberlik), 0-2 (Beraberlik ve Deplasman takım galibiyeti) ve 1-2 (Ev sahibi ve Deplasman takım galibiyeti)'
    },
    '2-100': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen gol kadar maça önde başlamış sayılır (gol avansı).'
    },
    '2-1': {
        name: 'Handikap',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen gol kadar maça önde başlamış sayılır (gol avansı).'
    },
    '2-643': {
        name: 'Ev Sahibi Takım Gol Yemeden Kazanır mı?',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '2-644': {
        name: 'Deplasman Takımı Gol Yemeden Kazanır mı?',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '4-613': {
        name: 'Handikaplı Maç Sonucu (Oyun Handikapı)',
        tooltip: 'Belirtilen oyuncuya verilen oyun avansıdır. Oyun avansı verilen takım maça verilen oyun sayısı kadar önde başlar. İki ihtimali bulunan bu oyunda (01) Ev Sahibi, (02) Deplasman şeklinde kodlanmaktadır.'
    },
    '4-4': {
        name: 'Maç Sonucu',
        tooltip: '90 dakika sonunda maçın nasıl biteceğinin tahmin edildiği oyun tipidir. Bu oyunda ikili tahmin yapılamaz. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '4-12': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen gol kadar maça önde başlamış sayılır (gol avansı).'
    },
    '4-23': {
        name: 'Maçın Geri Kalanını Kim Kazanır ',
        tooltip: 'Bahsin yapılacağı dakikada skor 0-0 sayılır ve maçın geri kalanının sonucu tahmin edilir. Üç tahmin vardır; Ev sahibi takım, Beraberlik, Deplasman takımı'
    },
    '4-27': {
        name: 'Uzatmaları Kim Kazanır',
        tooltip: '90 dakikanın ardından geçilen uzatma bölümünü kazanacak takımın tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım, Beraberlik, Deplasman takımı'
    },
    '4-47': {
        name: 'İlk Yarı Sonucu',
        tooltip: 'İlk yarının nasıl biteceğinin tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '4-129': {
        name: 'Çifte Şans',
        tooltip: 'Maç sonucuna ilişkin bu oyunda, bir tahmin iki farklı sonucu kapsar. Üç tahmin vardır; 1-0 (Ev sahibi takım galibiyeti ve Beraberlik), 0-2 (Beraberlik ve Deplasman takım galibiyeti) ve 1-2 (Ev sahibi ve ve Deplasman takım galibiyeti)'
    },
    '4-625': {
        name: 'İlk Yarı Çifte Şans',
        tooltip: 'İlk Yarı sonucuna ilişkin bu oyunda, bir tahmin iki farklı sonucu kapsar. Üç tahmin vardır; 1-0 (Ev sahibi takım galibiyeti ve Beraberlik), 0-2 (Beraberlik ve Deplasman takım galibiyeti) ve 1-2 (Ev sahibi ve Deplasman takım galibiyeti)'
    },
    '2-4': {
        name: 'Toplam Gol',
        tooltip: 'Maçta atılacak toplam gol sayısıyla ilgili oyun tipidir. Dört tahmin vardır; 0-1 gol, 2-3 gol, 4-5 gol, 6+'
    },
    '2-6': {
        name: 'Hangi Yarıda Daha Fazla Gol olur',
        tooltip: 'Maçta hangi yarıda daha çok gol atılacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; 1. yarı, 2. yarı, Berabere'
    },
    '2-43': {
        name: 'Ev Sahibi Gol Yemeden Bitirir',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '2-44': {
        name: 'Deplasman Gol Yemeden Bitirir',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '2-45': {
        name: 'Hangi Takım Gol Atar ',
        tooltip: 'Maçta hangi takımın gol atacağının tahmin edildiği oyun tipidir. Dört tahmin vardır; Sadece 1, Sadece 2, İkisi de, Gol olmaz'
    },
    '2-46': {
        name: 'İlk Yarı Toplam Gol',
        tooltip: 'İlk yarıda atılacak gol sayısının tahmin edildiği oyun tipidir. '
    },
    '2-60': {
        name: 'İlk Yarı Alt/Üst',
        tooltip: 'İlk yarıda atılacak toplam gol sayısıyla ilgili oyun tipidir.'
    },
    '2-84': {
        name: 'Ev Sahibi Takım Hangi Yarıda Daha Çok Gol Atar',
        tooltip: 'Üç tahmin vardır; İlk yarı, İkinci yarı, Eşit'
    },
    '2-85': {
        name: 'Deplasman Takımı Hangi Yarıda Daha Çok Gol Atar',
        tooltip: 'Üç tahmin vardır; İlk yarı, İkinci yarı, Eşit'
    },
    '2-87': {
        name: 'İlk Golü Hangi Takım Atar',
        tooltip: 'Maçta ilk golü hangi takımın atacağının tahmin edildiği oyun tipidir. Gol olmaz tahmini de yapılabilir.'
    },
    '2-89': {
        name: 'Karşılıklı Gol',
        tooltip: 'Maçta iki takımın da gol atacağı düşünülüyorsa Var tahmini, en az bir takımın gol atamayacağı düşünülüyorsa Yok tahmini seçilir.'
    },
    '2-91': {
        name: 'Tek / Çift',
        tooltip: 'Maçta atılacak toplam gol sayısının Tek veya Çift olacağının tahmin edildiği oyun tipidir. Örneğin; maç sonucu 0-0 (0 gol) veya 4-2 (6 gol) ise Çift, 2-1 (3 gol) veya 3-2 (5 gol) ise Tek tahmini kazanan bahistir.'
    },
    '2-101': {
        name: 'Toplam Gol Alt/Üst',
        tooltip: 'Maçta atılacak toplam gol sayısıyla ilgili oyun tipidir.'
    },
    '2-603': {
        name: 'Ev Sahibi Alt/Üst',
        tooltip: 'Ev sahibi takımın atacağı toplam gol sayısına göre tahmin yapılır.'
    },
    '2-604': {
        name: 'Deplasman Alt/Üst',
        tooltip: 'Deplasman takımının atacağı toplam gol sayısına göre tahmin yapılır.'
    },
    '4-14': {
        name: 'Toplam Gol Alt/Üst',
        tooltip: 'Maçta atılacak toplam gol sayısıyla ilgili oyun tipidir.'
    },
    '4-34': {
        name: 'Sıradaki Gol',
        tooltip: 'Bahsin yapıldığı andan sonra ilk golü hangi takımın atacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım, Deplasman takımı, Gol Olmaz'
    },
    '4-54': {
        name: 'Uzatmalar Alt/Üst',
        tooltip: 'Uzatmalarda atılacak toplam gol sayısına göre yapılan tahmindir.'
    },
    '4-63': {
        name: 'İlk Yarı Alt/Üst',
        tooltip: 'İlk yarıda atılacak toplam gol sayısıyla ilgili oyun tipidir.'
    },
    '4-131': {
        name: 'Karşılıklı Gol',
        tooltip: 'Maçta iki takımın da gol atacağı düşünülüyorsa Var tahmini, en az bir takımın gol atamayacağı düşünülüyorsa Yok tahmini seçilir.'
    },
    '4-136': {
        name: 'Tek / Çift',
        tooltip: 'Maçta atılacak toplam gol sayısının Tek veya Çift olacağının tahmin edildiği oyun tipidir. Örneğin; maç sonucu 0-0 (0 gol) veya 4-2 (6 gol) ise Çift, 2-1 (3 gol) veya 3-2 (5 gol) ise Tek tahmini kazanan bahistir.'
    },
    '4-199': {
        name: 'Hangi Yarıda Daha Fazla Gol olur',
        tooltip: 'Maçta hangi yarıda daha çok gol atılacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; 1. yarı, 2. yarı, Berabere'
    },
    '4-203': {
        name: 'Ev Sahibi Alt/Üst',
        tooltip: 'Ev sahibi takımın atacağı toplam gol sayısına göre tahmin yapılır.'
    },
    '4-207': {
        name: 'Deplasman Alt/Üst',
        tooltip: 'Deplasman takımının atacağı toplam gol sayısına göre tahmin yapılır.'
    },
    '4-262': {
        name: 'İlk Yarı Toplam Gol',
        tooltip: 'İlk yarıda atılacak gol sayısının tahmin edildiği oyun tipidir.'
    },
    '2-48': {
        name: 'Toplam Korner Alt/Üst',
        tooltip: 'Maçta atılacak toplam korner sayısına göre tahmin yapılır.'
    },
    '2-49': {
        name: 'İlk Yarı Toplam Korner Alt/Üst',
        tooltip: 'İlk yarıda atılacak toplam korner sayısına göre tahmin yapılır.'
    },
    '2-52': {
        name: 'Kim Daha Çok Korner Kullanır',
        tooltip: 'Maçta hangi takımın daha çok korner atacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım, Beraberlik, Deplasman takımı'
    },
    '2-53': {
        name: 'İlk Yarı Kim Daha Çok Korner Kullanır?',
        tooltip: 'İlk yarıda hangi takımın daha çok korner atacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım, Beraberlik, Deplasman takımı'
    },
    '2-56': {
        name: 'İlk Korneri Hangi Takım Kullanır',
        tooltip: 'İlk korneri hangi takımın atacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım, Deplasman takımı, Korner olmaz'
    },
    '2-67': {
        name: 'Toplam Korner',
        tooltip: 'Maçta toplam kaç kornerin atılacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; 0-8, 9-11, 12+'
    },
    '2-68': {
        name: 'İlk Yarı Toplam Korner',
        tooltip: 'İlk yarıda toplam kaç kornerin atılacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; 0-4, 5-6, 7+'
    },
    '4-210': {
        name: 'Kim Daha Çok Korner Kullanır',
        tooltip: 'Maçta hangi takımın daha çok korner atacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım, Beraberlik, Deplasman takımı'
    },
    '4-211': {
        name: 'Toplam Korner',
        tooltip: 'Maçta atılacak toplam korner sayısına göre tahmin yapılır.'
    },
    '4-609': {
        name: 'Tie Break Olur mu?',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '4-215': {
        name: 'Toplam Korner',
        tooltip: 'Maçta toplam kaç kornerin atılacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; 0-8, 9-11, 12+'
    },
    '4-217': {
        name: 'İlk Yarıda Kim Daha Çok Korner Kullanır',
        tooltip: 'İlk yarıda hangi takımın daha çok korner atacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım, Beraberlik, Deplasman takımı'
    },
    '4-218': {
        name: 'İlk Yarı Toplam Korner',
        tooltip: 'İlk yarıda atılacak toplam korner sayısına göre tahmin yapılır.'
    },
    '4-222': {
        name: 'İlk Yarı Toplam Korner',
        tooltip: 'İlk yarıda toplam kaç kornerin atılacağının tahmin edildiği oyun tipidir. Üç tahmin vardır; 0-4, 5-6, 7+'
    },
    '2-3': {
        name: 'İlk Golü Atan Oyuncu',
        tooltip: 'Maçta ilk golü hangi oyuncunun atacağının tahmin edildiği oyun tipidir.'
    },
    '2-12': {
        name: 'Gol Atan Oyuncu',
        tooltip: 'Maçta hangi oyuncunun gol atacağının tahmin edildiği oyun tipidir.'
    },
    '2-17': {
        name: 'Kırmızı Kart Olur mu',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '4-658': {
        name: 'Toplam kart sayısı Alt/Üst',
        tooltip: 'Maçta görülecek toplam kart sayısına göre tahmin yapılır.'
    },
    '2-658': {
        name: 'Toplam kart sayısı Alt/Üst',
        tooltip: 'Maçta gösterilecek toplam kart sayısına göre  tahmin yapılır.  Sarı kart 1 kart olarak ve kırmızı veya çift sarıdan kırmızı kart 2 kart olarak sayılır. Bir oyuncu için kırmızı karta neden olan ikinci sarı kart değerlendirmeye alınmaz.'
    },
    '2-661': {
        name: 'Son Golü Atan Oyuncu',
        tooltip: 'Maçta son golü hangi oyuncunun atacağının tahmin edildiği oyun tipidir.'
    },
    '2-800': {
        name: 'Hakem VAR Ekranını İzlemeye Kaç Kez Gider?',
        tooltip: 'Hakemin VAR ekranını izlemek için saha kenarına kaç defa gideceğinin tahmin edildiği oyun tipidir. Hakemin saha içerisinde iken VAR odası ile görüşmeleri dahil değildir.'
    },
    '2-801': {
        name: 'Toplam Ofsayt Sayısı',
        tooltip: 'Maçta olacak toplam ofsayt sayısına göre tahmin yapılır'
    },
    '2-802': {
        name: 'Penaltı Olur mu',
        tooltip: 'Maç içerisinde penaltı olup olmayacağının tahmin edildiği oyun tipidir.'
    },
    '2-803': {
        name: 'Kendi Kalesine Gol Olur mu',
        tooltip: 'Maç içerisinde herhangi bir oyuncunun kendi kalesine gol atıp atmayacağının tahmin edildiği oyun tipidir.'
    },
    '2-804': {
        name: 'Maç Sonu Tabelada Gösterilen Süre',
        tooltip: 'Maçın 90. dakikasında 4. hakemin tabelada kaç dakika uzatma göstereceğinin tahmin edildiği oyun tipidir.'
    },
    '2-86': {
        name: 'Maç Skoru',
        tooltip: 'Maç skorunun tahmin edildiği oyun tipidir.'
    },
    '4-252': {
        name: 'Maç Skoru',
        tooltip: 'Maç skorunun tahmin edildiği oyun tipidir.'
    },
    '2-107': {
        name: 'Hangi Çeyrekte Daha Çok Sayı Olur',
        tooltip: '4 çeyrek arasında hangisinde daha fazla sayı atılacağının tahmin edildiği oyun tipidir.'
    },
    '2-114': {
        name: 'Toplam Sayı Alt/Üst (Uzatmalar Dahil)',
        tooltip: 'Uzatmalar dahil olmak üzere, maçta atılacak toplam sayının programda belirtilen sayı bareminden daha az veya daha fazla olacağının tahmin edildiği oyun tipidir.'
    },
    '2-116': {
        name: 'İlk Yarı Toplam Sayı Alt/Üst',
        tooltip: 'Her iki takımın ilk yarı boyunca atacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '2-619': {
        name: 'Ev Sahibi Alt/Üst (Uzatmalar Dahil)',
        tooltip: 'Ev sahibi takımın maç süresince atacağı toplam sayıya göre (uzatmalar dahil) tahmin yapılır.'
    },
    '2-620': {
        name: 'Deplasman Alt/Üst (Uzatmalar Dahil)',
        tooltip: 'Deplasman takımının maç süresince atacağı toplam sayıya göre (uzatmalar dahil) tahmin yapılır.'
    },
    '2-622': {
        name: 'Maç Sonucu ve Alt/Üst (Uzatmalar Dahil)',
        tooltip: 'Kombine tahminin yapıldığı oyun tipidir. Aynı anda hem maç sonucunun hem de programda belirtilecek sayıdan daha az veya daha fazla sayı atılacağının (uzatmalar dahil) tahmini yapılır.'
    },
    '4-622': {
        name: '1. Oyuncu Toplam Oyun Alt/Üst',
        tooltip: '1. Oyuncunun (ev sahibi) kazanacağı toplam oyun sayısına göre tahmin yapılır. Handikaplar buçukludur ve bu nedenle beraberlik seçeneği bulunmamaktadır.'
    },
    '4-64': {
        name: 'İlk Yarı Toplam Sayı Alt/Üst',
        tooltip: 'Her iki takımın ilk yarı boyunca atacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-615': {
        name: 'Ev Sahibi Alt/Üst (Uzatmalar Dahil)',
        tooltip: 'Ev sahibi takımın maç süresince atacağı toplam sayıya göre (uzatmalar dahil) tahmin yapılır.'
    },
    '4-616': {
        name: 'Deplasman Alt/Üst (Uzatmalar Dahil)',
        tooltip: 'Deplasman takımının maç süresince atacağı toplam sayıya göre (uzatmalar dahil) tahmin yapılır.'
    },
    '1-2': {
        name: 'Maç Sonucu (Uzatmalar Dahil)',
        tooltip: 'Maçı kimin kazanacağının (Uzatmalar Dahil) tahmin edildiği oyundur. Oyunda handikap seçeneği yoktur. İki tahmin vardır; Ev sahibi takım, Deplasman takımı.'
    },
    '2-104': {
        name: 'Uzatma Evet / Hayır ',
        tooltip: 'Maçın berabere bitip uzatmaya gidilip gidilmeyeceğinin tahmin edildiği oyun tipidir. İki tahmin vardır; Evet, Hayır'
    },
    '2-105': {
        name: 'Hangi Takım Kaç Farkla Kazanır (Uzatmalar Dahil)',
        tooltip: 'Bir takımın kaç farklı skorla galip geleceğinin (uzatmalar dahil) tahmin edildiği oyun tipidir. Yedi tahmin vardır; Ev sahibi takım 11+, Ev sahibi takım 6-10, Ev sahibi takım 1-5, Deplasman takımı 11+, Deplasman takımı 6-10, Deplasman takımı 1-5'
    },
    '2-106': {
        name: 'İlk Yarı Sonucu',
        tooltip: 'İlk yarının nasıl biteceğinin tahmin edildiği oyun tipidir. İki tahmin vardır; Ev sahibi takım (1), Deplasman takımı (2)'
    },
    '2-111': {
        name: 'İlk Yarı / Maç Sonucu',
        tooltip: 'Maçın ilk yarısının sonucu ile maç sonucunun kombine tahmin edildiği oyun tipidir. Dokuz tahmin vardır; 1/1, 1/0, 1/2, 0/1, 0/2, 0/0, 2/1, 2/0, 2/2'
    },
    '2-113': {
        name: 'Handikaplı Maç Sonucu (Uzatmalar Dahil)',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen sayı kadar maça önde başlamış sayılır (sayı avansı) ve bu sayı hesaba katılarak maç sonucunun (uzatmalar dahil) tahmin edildiği oyun tipidir.'
    },
    '2-621': {
        name: 'İlk Yarı Sonucu',
        tooltip: 'İlk yarının nasıl biteceğinin tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '2-660': {
        name: '1. Çeyrek Sonucu',
        tooltip: 'İlk çeyreğin nasıl biteceğinin tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '4-71': {
        name: 'Maç Sonucu (Uzatmalar Dahil)',
        tooltip: 'Maçı kimin kazanacağının (Uzatmalar Dahil) tahmin edildiği oyundur. Oyunda handikap seçeneği yoktur. İki tahmin vardır; Ev sahibi takım, Deplasman takımı.'
    },
    '4-77': {
        name: 'İlk Yarı Sonucu',
        tooltip: 'İlk yarının nasıl biteceğinin tahmin edildiği oyun tipidir. İki tahmin vardır; Ev sahibi takım (1), Deplasman takımı (2)'
    },
    '4-186': {
        name: 'Uzatma Evet / Hayır ',
        tooltip: 'Maçın berabere bitip uzatmaya gidilip gidilmeyeceğinin tahmin edildiği oyun tipidir. İki tahmin vardır; Evet, Hayır'
    },
    '4-286': {
        name: 'Handikaplı Maç Sonucu (Uzatmalar Dahil)',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen sayı kadar maça önde başlamış sayılır (sayı avansı) ve bu sayı hesaba katılarak maç sonucunun (uzatmalar dahil) tahmin edildiği oyun tipidir.'
    },
    '4-607': {
        name: '1. Oyuncu Set Kazanır',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '4-608': {
        name: '2. Oyuncu Set Kazanır',
        tooltip: 'İki tahmin vardır; Evet, Hayır'
    },
    '4-606': {
        name: 'İlk Yarı Sonucu',
        tooltip: 'İlk yarının nasıl biteceğinin tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '4-673': {
        name: '1. Çeyrek Sonucu',
        tooltip: 'İlk çeyreğin nasıl biteceğinin tahmin edildiği oyun tipidir. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '1-10': {
        name: 'Maç Sonucu',
        tooltip: 'Maçı kimin kazanacağının tahmin edildiği oyundur. Oyunda handikap seçeneği yoktur. İki tahmin vardır; Ev sahibi takım, Deplasman takımı.'
    },
    '2-231': {
        name: 'Handikaplı Maç Sonucu (Oyun Handikapı)',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen set sayısı kadar maça önde başlamış sayılır (set avansı) ve bu sayı hesaba katılarak maçı hangi takımın galip bitireceğinin tahmin edildiği oyun tipidir.'
    },
    '4-114': {
        name: 'Maç Sonucu',
        tooltip: 'Maçı kimin kazanacağının tahmin edildiği oyundur. Oyunda handikap seçeneği yoktur. İki tahmin vardır; Ev sahibi takım, Deplasman takımı.'
    },
    '4-239': {
        name: 'Handikaplı Maç Sonucu (Oyun Handikapı)',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen set sayısı kadar maça önde başlamış sayılır (set avansı) ve bu sayı hesaba katılarak maçı hangi takımın galip bitireceğinin tahmin edildiği oyun tipidir.'
    },
    '2-217': {
        name: 'Toplam Sayı Alt/Üst',
        tooltip: 'Her iki takımın maç boyunca kazanacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-18': {
        name: 'Toplam Sayı Alt/Üst',
        tooltip: 'Her iki takımın maç boyunca kazanacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '2-220': {
        name: 'Maç Skoru',
        tooltip: 'Maç skorunun tahmin edildiği oyun tipidir.'
    },
    '4-277': {
        name: 'Maç Skoru',
        tooltip: 'Maç skorunun tahmin edildiği oyun tipidir.'
    },
    '1-16': {
        name: 'Maç Sonucu',
        tooltip: 'Maç sonucunun nasıl biteceğinin tahmin edildiği oyun tipidir. Bu oyunda ikili tahmin yapılamaz. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '2-185': {
        name: 'Çifte Şans',
        tooltip: 'Maç sonucuna ilişkin bu oyunda, bir tahmin iki farklı sonucu kapsar. Üç tahmin vardır; 1-0 (Ev sahibi takım galibiyeti ve Beraberlik), 0-2 (Beraberlik ve Deplasman takım galibiyeti) ve 1-2 (Ev sahibi ve Deplasman takım galibiyeti)'
    },
    '2-187': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen gol kadar maça önde başlamış sayılır (gol avansı).'
    }, '4-775': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen gol kadar maça önde başlamış sayılır (gol avansı).'
    },
    '4-3': {
        name: 'Maç Sonucu',
        tooltip: 'Maç sonucunun nasıl biteceğinin tahmin edildiği oyun tipidir. Bu oyunda ikili tahmin yapılamaz. Üç tahmin vardır; Ev sahibi takım (1), Beraberlik (0), Deplasman takımı (2)'
    },
    '4-633': {
        name: 'Çifte Şans',
        tooltip: 'Maç sonucuna ilişkin bu oyunda, bir tahmin iki farklı sonucu kapsar. Üç tahmin vardır; 1-0 (Ev sahibi takım galibiyeti ve Beraberlik), 0-2 (Beraberlik ve Deplasman takım galibiyeti) ve 1-2 (Ev sahibi ve Deplasman takım galibiyeti)'
    },
    '2-184': {
        name: 'Tek / Çift',
        tooltip: 'Maçta atılacak toplam gol sayısının Tek veya Çift olacağının tahmin edildiği oyun tipidir. Örneğin; maç sonucu 13-21 (34 gol) veya 40-20 (60 gol) ise Çift, 21-12 (33 gol) veya 19-16 (35 gol) ise Tek tercihi kazanan bahistir.'
    },
    '4-191': {
        name: 'Toplam Oyun Alt/Üst',
        tooltip: 'Her iki oyuncunun maç boyunca kazanacakları toplam oyunun programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '2-191': {
        name: 'Toplam Gol Alt/Üst',
        tooltip: 'Her iki takımın maç boyunca atacakları toplam golün programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-20': {
        name: 'Toplam Gol Alt/Üst',
        tooltip: 'Her iki takımın maç boyunca atacakları toplam golün programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-133': {
        name: 'Tek / Çift',
        tooltip: 'Maçta atılacak toplam gol sayısının Tek veya Çift olacağının tahmin edildiği oyun tipidir. Örneğin; maç sonucu 13-21 (34 gol) veya 40-20 (60 gol) ise Çift, 21-12 (33 gol) veya 19-16 (35 gol) ise Tek tercihi kazanan bahistir.'
    },
    '4-10': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: 'Handikap seçeneği bulunan takım, belirlenen gol kadar maça önde başlamış sayılır (gol avansı).'
    },
    '4-56': {
        name: 'Maç Sonucu',
        tooltip: 'Bir maçın sonucunun tahmin edildiği oyun türüdür. İki ihtimali bulunan bu oyunda (01) Ev Sahibi, (02) Deplasman şeklinde kodlanmaktadır.'
    },
    '1-9': {
        name: 'Maç Sonucu',
        tooltip: 'Bir maçın sonucunun tahmin edildiği oyun türüdür. İki ihtimali bulunan bu oyunda (01) Ev Sahibi, (02) Deplasman şeklinde kodlanmaktadır.'
    },
    '1-15': {
        name: 'Maç Sonucu',
        tooltip: 'Maçı kimin kazanacağının tahmin edildiği oyundur. Oyunda handikap seçeneği yoktur. İki tahmin vardır; Ev sahibi 1. Oyuncu, Deplasman 2. Oyuncu'
    },
    '2-641': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: 'Handikap seçeneği bulunan oyuncu, belirlenen frame sayısı kadar maça önde başlamış sayılır (frame avansı) ve bu sayı hesaba katılarak maçı hangi oyuncunun galip bitireceğinin tahmin edildiği oyun tipidir.'
    },
    '4-600': {
        name: 'Maç Sonucu',
        tooltip: 'Maçı kimin kazanacağının tahmin edildiği oyundur. Oyunda handikap seçeneği yoktur. İki tahmin vardır; Ev sahibi 1. Oyuncu, Deplasman 2. Oyuncu'
    },
    '4-602': {
        name: 'Toplam Frame Alt/Üst',
        tooltip: 'Her iki takımın maç boyunca kazanacakları toplam frame\'in programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '3-1': {
        name: 'Uzun Vadeli Sonuç',
        tooltip: 'Uzun vadeli etkinliğin nasıl sonuçlanacağının tahmin edilmesidir.'
    },
    '2-161': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: 'Handikap seçeneği bulunan oyuncu, belirlenen frame sayısı kadar maça önde başlamış sayılır (frame avansı) ve bu sayı hesaba katılarak maçı hangi oyuncunun galip bitireceğinin tahmin edildiği oyun tipidir.'
    },
    '2-163': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: 'Handikap seçeneği bulunan oyuncu, belirlenen frame sayısı kadar maça önde başlamış sayılır (frame avansı) ve bu sayı hesaba katılarak maçı hangi oyuncunun galip bitireceğinin tahmin edildiği oyun tipidir.'
    },
    '2-164': {
        name: 'Toplam Gol Alt/Üst',
        tooltip: 'Her iki takımın maç boyunca atacakları toplam golün programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-146': {
        name: 'Birinci Set Altı/Üstü',
        tooltip: 'Her iki takımın birinci set boyunca atacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-157': {
        name: 'İkinci Set Altı/Üstü',
        tooltip: 'Her iki takımın ikinci set boyunca atacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-167': {
        name: 'Üçüncü Set Altı/Üstü',
        tooltip: 'Her iki takımın üçüncü set boyunca atacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-171': {
        name: 'Dördüncü Set Altı/Üstü',
        tooltip: 'Her iki takımın dördüncü set boyunca atacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-190': {
        name: 'Beşinci Set Altı/Üstü',
        tooltip: 'Her iki takımın beşinci set boyunca atacakları toplam sayının programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '4-21': {
        name: 'Toplam Gol Altı/Üstü',
        tooltip: 'Her iki takımın maç boyunca atacakları toplam golün programda belirtilen sayının altında veya üstünde olduğunun tahmin edilmesidir.'
    },
    '2-702': {
        name: 'Set Handikap',
        tooltip: 'Belirtilen oyuncuya verilen set avansıdır. Set avansı verilen oyuncu, maça verilen set sayısı kadar önde başlar. İki tahmin vardır; Ev Sahibi, Deplasman.'
    },
    '4-712': {
        name: 'Set Handikap',
        tooltip: 'Belirtilen oyuncuya verilen set avansıdır. Set avansı verilen oyuncu, maça verilen set sayısı kadar önde başlar. İki tahmin vardır; Ev Sahibi, Deplasman.'
    },
    '2-701': {
        name: 'Toplam Oyun Tek/Çift',
        tooltip: 'Bir maçta oynanacak olan toplam oyunun tek ya da çift sayı ile biteceğinin tahmin edildiği oyun türüdür. İki tahmin vardır; Tek, Çift.'
    },
    '4-711': {
        name: 'Toplam Oyun Tek/Çift',
        tooltip: 'Bir maçta oynanacak olan toplam oyunun tek ya da çift sayı ile biteceğinin tahmin edildiği oyun türüdür. İki tahmin vardır; Tek, Çift.'
    },
    '2-703': {
        name: 'Maç Sonucu ve Altı/Üstü',
        tooltip: 'Kombine tahminin yapıldığı oyun türüdür. Aynı anda hem maç sonucunun hem de oynanacak toplam oyun sayısının belirlenen sayıdan daha az veya daha fazla olacağının tahmini yapılır.'
    },
    '4-713': {
        name: 'Maç Sonucu ve Altı/Üstü',
        tooltip: 'Kombine tahminin yapıldığı oyun türüdür. Aynı anda hem maç sonucunun hem de oynanacak toplam oyun sayısının belirlenen sayıdan daha az veya daha fazla olacağının tahmini yapılır.'
    },
    '2-704': {
        name: 'Oyun Vermeden Set Kazanılır mı',
        tooltip: 'Karşılaşmada taraflardan herhangi birinin oyun vermeden set kazanıp kazanmayacağının tahmini yapılır. İki tahmin vardır; Evet, Hayır'
    },
    '4-714': {
        name: 'Oyun Vermeden Set Kazanılır mı',
        tooltip: 'Karşılaşmada taraflardan herhangi birinin oyun vermeden set kazanıp kazanmayacağının tahmini yapılır. İki tahmin vardır; Evet, Hayır'
    },
    '2-705': {
        name: 'İlk Set Sonucu/Maç Sonucu',
        tooltip: 'Maçın ilk setinin sonucu ile maç sonucunun kombine edildiği oyun türüdür.'
    },
    '4-715': {
        name: 'İlk Set Sonucu/Maç Sonucu',
        tooltip: 'Maçın ilk setinin sonucu ile maç sonucunun kombine edildiği oyun türüdür.'
    },
    '2-709': {
        name: 'Ev Sahibi Sadece Bir Set Kazanır',
        tooltip: 'Maç sonucunda ev sahibinin sadece bir set kazanıp kazanmayacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '4-719': {
        name: 'Ev Sahibi Sadece Bir Set Kazanır',
        tooltip: 'Maç sonucunda ev sahibinin sadece bir set kazanıp kazanmayacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '2-710': {
        name: 'Deplasman Sadece Bir Set Kazanır',
        tooltip: 'Maç sonucunda deplasman takımının sadece bir set kazanıp kazanmayacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '4-720': {
        name: 'Deplasman Sadece Bir Set Kazanır',
        tooltip: 'Maç sonucunda deplasman takımının sadece bir set kazanıp kazanmayacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    /*  '4-718': {
          name: '1. set 1. oyun skoru',
          tooltip: 'Belirtilen setteki belirtilen oyunun hangi skorla sonuçlanacağının tahmin edildiği oyun türüdür.'
      },
      '4-743': {
          name: '2. set 1. oyun skoru',
          tooltip: 'Belirtilen setteki belirtilen oyunun hangi skorla sonuçlanacağının tahmin edildiği oyun türüdür.'
      },
      '4-744': {
          name: '3. set 1. oyun skoru',
          tooltip: 'Belirtilen setteki belirtilen oyunun hangi skorla sonuçlanacağının tahmin edildiği oyun türüdür.'
      },
      '4-745': {
          name: '4. set 1. oyun skoru',
          tooltip: 'Belirtilen setteki belirtilen oyunun hangi skorla sonuçlanacağının tahmin edildiği oyun türüdür.'
      },
      '4-746': {
          name: '5. set 1. oyun skoru',
          tooltip: 'Belirtilen setteki belirtilen oyunun hangi skorla sonuçlanacağının tahmin edildiği oyun türüdür.'
      },*/
    '4-753': {
        name: 'Kim Kazanır'
    },
    '4-757': {
        name: 'En Hızlı Tur'
    },
    '4-758': {
        name: 'İlk 3\'e kimler girer'
    },
    '2-745': {
        name: 'Beraberlikte İade',
        tooltip: 'Maçı hangi tarafın kazanacağının tahmin edildiği ve skorun eşit olması durumunda oranın bir (1.00) sayılacağı oyun türüdür.'
    },
    '4-741': {
        name: 'Beraberlikte İade',
        tooltip: 'Maçı hangi tarafın kazanacağının tahmin edildiği ve skorun eşit olması durumunda oranın bir (1.00) sayılacağı oyun türüdür.'
    },
    '2-746': {
        name: 'Ev Sahibi İlk Yarı Altı/Üstü',
        tooltip: 'Ev sahibi takımın ilk yarıda atacağı toplam sayıya göre tahmin yapılır.'
    },
    '2-747': {
        name: 'Deplasman İlk Yarı Altı/Üstü',
        tooltip: 'Deplasman takımının ilk yarıda atacağı toplam sayıya göre tahmin yapılır.'
    },
    '2-748': {
        name: 'Hangi Yarıda Daha Fazla Sayı Olur',
        tooltip: 'Hangi yarıda daha fazla sayı atılacağının tahmin edildiği oyun türüdür. 3 tahmin vardır; 1. Yarı, 2. Yarı, Eşit.'
    },
    '2-749': {
        name: '1. Çeyrek Altı/Üstü',
        tooltip: 'Her iki takımın belirtilen çeyrekte atacakları toplam sayının belirlenen sayının altında veya üstünde olacağının tahmin edildiği oyun türüdür.'
    },
    '2-750': {
        name: 'Sayı Aralığı (Uzatmalar Hariç)',
        tooltip: ''
    },
    '2-769': {
        name: 'Sayı Aralığı (Uzatmalar Hariç)',
        tooltip: ''
    },
    '4-742': {
        name: '1. Çeyrek Altı/Üstü',
        tooltip: 'Her iki takımın belirtilen çeyrekte atacakları toplam sayının belirlenen sayının altında veya üstünde olacağının tahmin edildiği oyun türüdür.'
    },
    '2-758': {
        name: '2. Çeyrek Altı/Üstü',
        tooltip: 'Her iki takımın belirtilen çeyrekte atacakları toplam sayının belirlenen sayının altında veya üstünde olacağının tahmin edildiği oyun türüdür.'
    },
    '4-747': {
        name: '2. Çeyrek Altı/Üstü',
        tooltip: 'Her iki takımın belirtilen çeyrekte atacakları toplam sayının belirlenen sayının altında veya üstünde olacağının tahmin edildiği oyun türüdür.'
    },
    '2-759': {
        name: '3. Çeyrek Altı/Üstü',
        tooltip: 'Her iki takımın belirtilen çeyrekte atacakları toplam sayının belirlenen sayının altında veya üstünde olacağının tahmin edildiği oyun türüdür.'
    },
    '4-748': {
        name: '3. Çeyrek Altı/Üstü',
        tooltip: 'Her iki takımın belirtilen çeyrekte atacakları toplam sayının belirlenen sayının altında veya üstünde olacağının tahmin edildiği oyun türüdür.'
    },
    '2-760': {
        name: '4. Çeyrek Altı/Üstü',
        tooltip: 'Her iki takımın belirtilen çeyrekte atacakları toplam sayının belirlenen sayının altında veya üstünde olacağının tahmin edildiği oyun türüdür.'
    },
    '4-749': {
        name: '4. Çeyrek Altı/Üstü',
        tooltip: 'Her iki takımın belirtilen çeyrekte atacakları toplam sayının belirlenen sayının altında veya üstünde olacağının tahmin edildiği oyun türüdür.'
    },
    '2-711': {
        name: 'Ev Sahibi Set Kazanır',
        tooltip: 'Ev sahibi takımın set kazanıp kazanamayacağına dair tahmin yapılan oyun türüdür. İki tahmin vardır; Evet, Hayır.'
    },
    '2-712': {
        name: 'Deplasman Set Kazanır',
        tooltip: 'Deplasman takımının set kazanıp kazanamayacağına dair tahmin yapılan oyun türüdür. İki tahmin vardır; Evet, Hayır.'
    },
    '2-713': {
        name: 'Toplam Set Sayısı',
        tooltip: 'Bir maçta oynanacak olan toplam set sayısının tahmin edildiği oyun türüdür. Üç tahmin vardır; 3, 4, 5.'
    },
    '4-723': {
        name: 'Toplam Set Sayısı',
        tooltip: 'Bir maçta oynanacak olan toplam set sayısının tahmin edildiği oyun türüdür. Üç tahmin vardır; 3, 4, 5.'
    },
    // '2-714': {
    //   name: 'Set Sonucu',
    //   tooltip: 'Belirtilen seti hangi takımın kazanacağının tahmin edildiği oyun türüdür.'
    // },
    // '4-724': {
    //   name: 'Set Sonucu',
    //   tooltip: 'Belirtilen seti hangi takımın kazanacağının tahmin edildiği oyun türüdür.'
    // },
    '2-715': {
        name: 'Ev Sahibi Sadece Bir Set Kazanır',
        tooltip: 'Maç sonucunda ev sahibinin sadece bir set kazanıp kazanmayacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '4-725': {
        name: 'Ev Sahibi Sadece Bir Set Kazanır',
        tooltip: 'Maç sonucunda ev sahibinin sadece bir set kazanıp kazanmayacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '2-716': {
        name: 'Deplasman Sadece Bir Set Kazanır',
        tooltip: 'Maç sonucunda deplasman takımının sadece bir set kazanıp kazanmayacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '4-726': {
        name: 'Deplasman Sadece Bir Set Kazanır',
        tooltip: 'Maç sonucunda deplasman takımının sadece bir set kazanıp kazanmayacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '2-737': {
        name: 'Korner Tek/Çift',
        tooltip: 'Bir maçta atılacak toplam korner sayısının tek mi çift mi olacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Tek, Çift'
    },
    '4-727': {
        name: 'Korner Tek/Çift',
        tooltip: 'Bir maçta atılacak toplam korner sayısının tek mi çift mi olacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Tek, Çift'
    },
    '2-717': {
        name: 'İlk Yarı Skoru',
        tooltip: 'İlk yarı skorunun tahmin edildiği oyun türüdür.'
    },
    '4-728': {
        name: 'İlk Yarı Skoru',
        tooltip: 'İlk yarı skorunun tahmin edildiği oyun türüdür.'
    },
    '2-718': {
        name: 'İY/MS Skorları',
        tooltip: 'İlk yarı ve maç sonucu skorunun tahmin edildiği oyun türüdür.'
    },
    '2-719': {
        name: 'İlk Yarı Tek/Çift',
        tooltip: 'İlk yarı skoru toplamının tek mi yoksa çift mi olacağının tahmin edildiği oyun türüdür.'
    },
    '2-729': {
        name: 'İki Yarı da Alt 1.5',
        tooltip: ''
    },
    '2-730': {
        name: 'İki Yarı da Üst 1.5',
        tooltip: ''
    },
    '4-729': {
        name: 'Sıradaki Gol Nasıl Atılır',
        tooltip: 'Tahminin yapıldığı andan sonra atılacak golün hangi şekilde gerçekleşeceğinin tahmin edildiği oyun türüdür. 6 tahmin vardır; Şutla, Kafayla, Kendi Kalesine, Penaltıyla, Frikikle, Hiçbiri'
    },
    '4-730': {
        name: 'Sıradaki Golü Hangi Oyuncu Atar',
        tooltip: 'Tahminin yapıldığı andan sonra hangi oyuncunun gol atacağının tahmin edildiği oyun türüdür.'
    },
    '2-700': {
        name: 'Altı/Üstü ve Karşılıklı Gol',
        tooltip: 'Kombine tahminin yapıldığı oyun türüdür. Aynı anda hem atılacak toplam gol sayısının belirlenen sayıdan daha az veya daha fazla olacağının, hem de karşılıklı gol olup olmayacağının tahmini yapılır. 4 tahmin vardır; Üst ve Var, Alt ve Var, Üst ve Yok, Alt ve Yok'
    },
    '4-731': {
        name: 'Ev Sahibi Toplam Korner Altı/Üstü',
        tooltip: 'Maçta ev sahibi takımın atacağı toplam korner sayısının belirlenen sayıdan daha az veya daha fazla olacağının tahmin edildiği oyun türüdür.'
    },
    '4-732': {
        name: 'Deplasman Toplam Korner Altı/Üstü',
        tooltip: 'Maçta deplasman takımının atacağı toplam korner sayısının belirlenen sayıdan daha az veya daha fazla olacağının tahmin edildiği oyun türüdür.'
    },
    '4-733': {
        name: 'Ev Sahibi Toplam Kart Altı/Üstü',
        tooltip: 'Maçta ev sahibi takıma gösterilecek toplam kart sayısının belirlenen sayıdan daha az veya daha fazla olacağının tahmin edildiği oyun türüdür.  Sarı kart 1 kart olarak ve kırmızı veya çift sarıdan kırmızı kart 2 kart olarak sayılır. Bir oyuncu için kırmızı karta neden olan ikinci sarı kart değerlendirmeye alınmaz.'
    },
    '4-734': {
        name: 'Deplasman Toplam Kart Altı/Üstü',
        tooltip: 'Maçta deplasman takımına gösterilecek toplam kart sayısının belirlenen sayıdan daha az veya daha fazla olacağının tahmin edildiği oyun türüdür.  Sarı kart 1 kart olarak ve kırmızı veya çift sarıdan kırmızı kart 2 kart olarak sayılır. Bir oyuncu için kırmızı karta neden olan ikinci sarı kart değerlendirmeye alınmaz.'
    },
    '2-727': {
        name: 'Ev Sahibi Yarı Kazanır',
        tooltip: 'Ev sahibi takımın maçın birinci ya da ikinci yarısında rakibinden daha fazla gol atacağının tahmin edildiği oyun türüdür. İkinci yarı başlangıcında skor 0-0 sayılır. '
    },
    '2-728': {
        name: 'Deplasman Yarı Kazanır',
        tooltip: 'Deplasman takımının maçın birinci ya da ikinci yarısında rakibinden daha fazla gol atacağının tahmin edildiği oyun türüdür. İkinci yarı başlangıcında skor 0-0 sayılır. '
    },
    '2-720': {
        name: 'İlk Yarı Karşılıklı Gol',
        tooltip: 'Karşılaşmanın ilk yarısında her iki takımın da gol atıp atamayacağının tahmin edildiği oyun türüdür.'
    },
    '4-735': {
        name: 'İlk Yarı Karşılıklı Gol',
        tooltip: 'Karşılaşmanın ilk yarısında her iki takımın da gol atıp atamayacağının tahmin edildiği oyun türüdür.'
    },
    '2-696': {
        name: 'Beraberlikte İade',
        tooltip: '90 dakika sonunda maçın nasıl biteceğinin tahmin edildiği ve skorun eşit olması durumunda oranın bir (1.00) sayılacağı oyun türüdür.'
    },
    '4-706': {
        name: 'Beraberlikte İade',
        tooltip: '90 dakika sonunda maçın nasıl biteceğinin tahmin edildiği ve skorun eşit olması durumunda oranın bir (1.00) sayılacağı oyun türüdür.'
    },
    // '4-736': {
    //   name: 'Sıradaki Korneri Hangi Takım Atar',
    //   tooltip: 'Tahminin yapıldığı andan sonra ilk korneri hangi takımın atacağının tahmin edildiği oyun türüdür. Üç tahmin vardır; Ev sahibi takım, Olmaz, Deplasman takımı.'
    // },
    '2-722': {
        name: 'Ev Sahibi İlk Yarı Altı/Üstü',
        tooltip: 'Ev sahibi takımın maçın ilk yarısında atacağı toplam gol sayısının belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür.'
    },
    '4-737': {
        name: 'Ev Sahibi İlk Yarı Altı/Üstü',
        tooltip: 'Ev sahibi takımın maçın ilk yarısında atacağı toplam gol sayısının belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür.'
    },
    '2-723': {
        name: 'Deplasman İlk Yarı Altı/Üstü',
        tooltip: 'Deplasman takımının maçın ilk yarısında atacağı toplam gol sayısının belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür.'
    },
    '4-738': {
        name: 'Deplasman İlk Yarı Altı/Üstü',
        tooltip: 'Deplasman takımının maçın ilk yarısında atacağı toplam gol sayısının belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür.'
    },
    '2-724': {
        name: 'İlk Yarı Sonucu ve İlk Yarı Altı/Üstü',
        tooltip: 'Kombine tahminin yapıldığı oyun türüdür. Aynı anda hem ilk yarı sonucunun hem de ilk yarıda atılacak toplam gol sayısının belirlenen sayıdan daha az veya daha fazla olacağının tahmini yapılır.'
    },
    '4-739': {
        name: 'İlk Yarı Sonucu ve İlk Yarı Altı/Üstü',
        tooltip: 'Kombine tahminin yapıldığı oyun türüdür. Aynı anda hem ilk yarı sonucunun hem de ilk yarıda atılacak toplam gol sayısının belirlenen sayıdan daha az veya daha fazla olacağının tahmini yapılır.'
    },
    '2-698': {
        name: 'Maç Sonucu ve Karşılıklı Gol',
        tooltip: 'Kombine tahminin yapıldığı oyun türüdür. Aynı anda hem karşılaşmanın nasıl sonuçlanacağının, hem de karşılaşmada her iki takımın da gol atıp atamayacağının tahmini yapılır. 6 tahmin vardır; 1 ve Var, 1 ve Yok, 0 ve Var, 0 ve Yok, 2 ve Var, 2 ve Yok'
    },
    '4-708': {
        name: 'Maç Sonucu ve Karşılıklı Gol',
        tooltip: 'Kombine tahminin yapıldığı oyun türüdür. Aynı anda hem karşılaşmanın nasıl sonuçlanacağının, hem de karşılaşmada her iki takımın da gol atıp atamayacağının tahmini yapılır. 6 tahmin vardır; 1 ve Var, 1 ve Yok, 0 ve Var, 0 ve Yok, 2 ve Var, 2 ve Yok'
    },
    '2-699': {
        name: 'İlk Yarı Sonucu ve İlk Yarı Karşılıklı Gol',
        tooltip: 'Kombine tahminin yapıldığı oyun türüdür. Aynı anda hem karşılaşmanın ilk yarısının nasıl sonuçlanacağının, hem de karşılaşmanın ilk yarısında her iki takımın da gol atıp atamayacağının tahmini yapılır. 6 tahmin vardır; 1 ve Var, 1 ve Yok, 0 ve Var, 0 ve Yok, 2 ve Var, 2 ve Yok'
    },
    '4-709': {
        name: 'İlk Yarı Sonucu ve İlk Yarı Karşılıklı Gol',
        tooltip: 'Kombine tahminin yapıldığı oyun türüdür. Aynı anda hem karşılaşmanın ilk yarısının nasıl sonuçlanacağının, hem de karşılaşmanın ilk yarısında her iki takımın da gol atıp atamayacağının tahmini yapılır. 6 tahmin vardır; 1 ve Var, 1 ve Yok, 0 ve Var, 0 ve Yok, 2 ve Var, 2 ve Yok'
    },
    '2-731': {
        name: 'Ev Sahibi veya Alt',
        tooltip: 'Karşılaşmayı ev sahibi takımın kazanıp kazanmayacağının veya maç sonucunun belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '2-732': {
        name: 'Ev Sahibi veya Üst',
        tooltip: 'Karşılaşmayı ev sahibi takımın kazanıp kazanmayacağının veya maç sonucunun belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '2-733': {
        name: 'Deplasman veya Alt',
        tooltip: 'Karşılaşmayı deplasman takımının kazanıp kazanmayacağının veya maç sonucunun belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '2-734': {
        name: 'Deplasman veya Üst',
        tooltip: 'Karşılaşmayı deplasman takımının kazanıp kazanmayacağının veya maç sonucunun belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '2-735': {
        name: 'Beraberlik veya Alt',
        tooltip: 'Karşılaşmada ev sahibi ve deplasman takımının atacağı gollerin eşit olup olmayacağının veya maç sonucunun belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '2-736': {
        name: 'Beraberlik veya Üst',
        tooltip: 'Karşılaşmada ev sahibi ve deplasman takımının atacağı gollerin eşit olup olmayacağının veya maç sonucunun belirlenen sayıdan az veya daha fazla olacağının tahmin edildiği oyun türüdür. İki tahmin vardır; Evet, Hayır'
    },
    '2-725': {
        name: 'Hangi Takım Tur Atlar',
        tooltip: 'Bir eşleşmede hangi tarafın tur atlayacağının tahmin edildiği oyun türüdür. Uzatmalar ve seri penaltı atışları dahildir. İki seçenek vardır; Ev Sahibi Takım, Deplasman Takımı'
    },
    '4-740': {
        name: 'Hangi Takım Tur Atlar',
        tooltip: 'Bir eşleşmede hangi tarafın tur atlayacağının tahmin edildiği oyun türüdür. Uzatmalar ve seri penaltı atışları dahildir. İki seçenek vardır; Ev Sahibi Takım, Deplasman Takımı'
    },
    '4-707': {
        name: 'İlk Yarı Kalanını Kim Kazanır',
        tooltip: 'Tahminin yapılacağı dakikada mevcut skor dikkate alınmadan (yapılan bahis öncesinde atılan goller dikkate alınmaksızın) maçın ilk yarısının geri kalanının sonucu tahmin edilir. Üç tahmin vardır; Ev sahibi takım, Beraberlik, Deplasman takımı'
    },
    '4-754': {
        name: 'Hangi Takım Kazanır',
        tooltip: ''
    },
    '4-755': {
        name: 'Kim Daha Üstte Bitirir',
        tooltip: ''
    },
    '4-756': {
        name: 'Hangi Takım Daha Üstte Bitirir',
        tooltip: ''
    },
    '1-21': {
        name: 'Maç Sonucu',
        tooltip: '',
    },
    '2-601': {
        name: 'Maç Sonucu (Uzatmalar Dahil)',
        tooltip: '',
    },
    '4-9': {
        name: 'Handikaplı Maç Sonucu',
        tooltip: '',
    },
    '4-135': {
        name: 'Tek/Çift',
        tooltip: '',
    },
    '2-126': {
        name: 'İlk Periyot Sonucu',
        tooltip: '',
    },
    '2-157': {
        name: 'Karşılıklı Gol',
        tooltip: '',
    },
    '2-137': {
        name: 'İkinci Periyot Sonucu',
        tooltip: '',
    },
    '2-135': {
        name: 'Hangi Periyotta Daha Fazla Gol Olur',
        tooltip: '',
    },
    '2-158': {
        name: 'Tek/Çift',
        tooltip: '',
    },
    '2-617': {
        name: 'Hangi Takım Kaç Farkla Kazanır',
        tooltip: '',
    },
    // '2-127': {
    //     name: 'Üçüncü Periyot Sonucu',
    //     tooltip: '',
    // },
    // '4-37': {
    //     name: 'Üçüncü Periyot Sonucu',
    //     tooltip: '',
    // },
    '4-639': {
        name: 'Hangi Takım Kaç Farkla Kazanır',
        tooltip: '',
    },
    '4-1': {
        name: 'Maç Sonucu',
    },
    '4-120': {
        name: 'Maç Sonucu (Uzatmalar Dahil)',
    },
    '4-619': {
        name: 'Karşılıklı Gol',
    },
    '4-128': {
        name: 'Çifte Şans',
    },
    '4-767': {
        name: '1. Set 4. Oyun Deuce Olur mu',
    },
    '2-828': {
        name: 'İlk Yarı Altı/Üstü',
    },
    '4-806': {
        name: 'İlk Yarı Altı/Üstü',
    },
    '2-829': {
        name: 'İlk Yarı Handikap',
    },
    '2-807': {
        name: 'İlk Yarı Handikap',
    },
    '2-826': {
        name: 'Deplasman Altı/Üstü',
    },
    '2-825': {
        name: 'Ev Sahibi Altı/Üstü',
    },
    '4-808': {
        name: 'İlk Yarı Ev Sahibi Altı/Üstü',
    },
    '4-809': {
        name: 'İlk Yarı Deplasman Altı/Üstü',
    },
    '4-807': {
        name: 'İlk Yarı Handikap',
    },
    '4-804': {
        name: 'Deplasman Altı/Üstü',
    },
    '4-803': {
        name: 'Ev Sahibi Altı/Üstü',
    },
    '4-792': {
        name: 'Toplam Kart Altı/Üstü',
    },
    '4-829': {
        name: 'İlk Yarı Ev Sahibi Korner Altı/Üstü',
    },
    '4-830': {
        name: 'İlk Yarı Deplasman Korner Altı/Üstü',
    },
    '4-768': undefined,
};
export var listOfOddsContainingPlayerNames = [
    '2-3',
    '2-12',
    '2-788',
    '2-789',
    '2-790',
    '2-791',
    '2-792',
    '2-793',
    '2-794',
    '2-795',
    '2-796',
    '2-797',
    '2-798',
    '2-799',
    '2-800',
];
export var listOfOddsContainingLongNames = [
    '2-820',
    '2-830'
];
export var CustomMarketName = function (marketType, marketName, specialOddValue) {
    if (['4-797', '4-796'].includes(marketType)) {
        if (marketType === '4-797' && specialOddValue) {
            return "".concat(specialOddValue.toString().split('|')[0], ". \u00C7eyrek Deplasman Alt\u0131/\u00DCst\u00FC ").concat(specialOddValue.toString().split('|')[1]);
        }
        if (marketType === '4-796' && specialOddValue) {
            return "".concat(specialOddValue.toString().split('|')[0], ". \u00C7eyrek Ev sahibi Alt\u0131/\u00DCst\u00FC ").concat(specialOddValue.toString().split('|')[1]);
        }
        return marketName;
    }
    return marketName;
};
export var replaceSpecialOddsValueOutcomeName = [
    '4-804', "4-808", "4-809", "4-806", "4-803", "2-826", "2-828", "2-825", "4-37"
];
export var hideSpecialOddsValueOutcomeName = [
    "2-648", "2-647",
];
