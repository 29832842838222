var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import IconBell from '../../../../assets/feather/bell.svg';
import bonusChest from '../../../../assets/img/bonus-chest.gif';
import Copy from '../../../../assets/feather/copy.svg';
import DefaultAvatar from "../../../../assets/img/avatar.png";
import { loginSubject, useAuthContext } from "../../../../store/login.store";
import { env } from "../../../../definitions/global.vars";
import { doLogout } from "../../../../services/login.services";
import { Link, useNavigate } from "react-router-dom";
import paths from "../../../../app/paths";
import { useForceUpdate } from "../../../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../../../store/betslip.store";
import iconFather from "../../../../assets/feather/father-at-yarisi.svg";
import { modalServiceSubject } from "../../../../components/modal/modal";
import Bonus from "../bonus/bonus";
function Profile(probs) {
    var openPage = window.location.href;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var _a = useAuthContext(), isAuthenticated = _a.isAuthenticated, setIsAuthenticated = _a.setIsAuthenticated;
    var member = probs.member;
    var _b = useState(false), showBonusModal = _b[0], setShowBonusModal = _b[1];
    if (!isAuthenticated) {
        return _jsx(_Fragment, {}, void 0);
    }
    var logout = function () {
        doLogout();
        var pathname = window.location.pathname.slice(1);
        if (pathname === paths.LOGIN || pathname === '') {
            pathname = '/';
        }
        setIsAuthenticated(false);
        betSlipVisibleSubject.next(false);
        navigate('/giris-yap');
    };
    useEffect(function () {
        var loginSubject_subscription = loginSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    });
    var copyMemberId = function () {
        var element = document.getElementById("copy");
        navigator.clipboard.writeText(member.id);
        element.style.display = "inline";
        element.classList.add("elementToFadeInAndOut");
    };
    function menuSelected(status) {
        var menu = document.getElementById("profile-menu");
        if (status) {
            menu.style.display = "block";
        }
        else {
            menu.style.display = "none";
        }
    }
    var openBonusModal = function () {
        // setShowBonusModal(true);
        modalServiceSubject.next({
            title: false,
            closeIcon: true,
            width: '700px',
            height: 'auto',
            content: _jsx(Bonus, {}, void 0),
            timeout: 0
        });
    };
    if (innerWidth < 968) {
        return (_jsxs("div", __assign({ className: "profile float-right pr-3" }, { children: [member.photo ? _jsx("img", { className: "avatar hidden-sm", src: env.core_api + "/" + member.photo, alt: "" }, void 0) :
                    _jsx("img", { className: "avatar hidden-sm", src: DefaultAvatar, alt: "" }, void 0), _jsxs("div", __assign({ className: "member-info", onMouseOver: function () { return menuSelected(true); }, onMouseOut: function () { return menuSelected(false); } }, { children: [_jsxs("div", { children: [_jsx("span", __assign({ id: "copy", className: "copy-tooltip-navbar " }, { children: "Kopyaland\u0131" }), void 0), "\u00DCye No: ", member.id, " \u00A0", _jsx("img", { src: Copy, alt: "copy", style: { filter: 'invert(1)' }, className: "cursor-pointer", id: "copy-icon", onClick: function () { return copyMemberId(); } }, void 0)] }, void 0), _jsxs("div", __assign({ className: "balance", style: { color: '#FFFFFF' } }, { children: [_jsx("span", { children: "Bakiye:" }, void 0), " ", member.getAvailableBalance().toFixed(2), "\u20BA"] }), void 0), _jsxs("ul", __assign({ className: 'profile-ul', id: "profile-menu", onClick: function () { return menuSelected(false); } }, { children: [_jsx("li", { children: _jsxs("div", { children: ["TL Bakiye ", _jsxs("span", __assign({ className: "float-right fw-bold" }, { children: [parseFloat(member.debit_balance).toFixed(2), " \u20BA"] }), void 0)] }, void 0) }, void 0), _jsx("li", __assign({ style: { borderBottom: "1px solid var(--color-text-medium)", paddingBottom: '5px' } }, { children: _jsxs("div", { children: ["OP Bakiye", _jsxs("span", __assign({ className: "float-right fw-bold" }, { children: [(parseFloat(member.point_balance) / 100).toFixed(2), " \u20BA"] }), void 0)] }, void 0) }), void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/uyelik", className: openPage.includes("hesabim/uyelik") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "\u00DCyelik Bilgilerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/para-yatir", className: openPage.includes("hesabim/para-yatir") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Para Yat\u0131r" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/para-cek", className: openPage.includes("hesabim/para-cek") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Para \u00C7ek" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/iddaa/devam-edenler", className: openPage.includes("hesabim/iddaa/devam-edenler") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "\u0130ddaa Kuponlar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/spor-toto/devam-edenler", className: openPage.includes("hesabim/spor-toto/devam-edenler") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Spor Toto Kuponlar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/esya-piyangosu/devam-eden-cekilisler", className: openPage.includes("hesabim/esya-piyangosu/devam-eden-cekilisler") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Piyango Biletlerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/oyna-kazan/kartlarim", className: openPage.includes("hesabim/oyna-kazan/kartlarim") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Oyna Kazan Kartlar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx("a", __assign({ onClick: function () {
                                            openBonusModal();
                                        }, style: { cursor: "pointer" } }, { children: "Puan Sepeti" }), void 0) }, void 0), env.tjk && _jsx("li", { children: _jsxs(Link, __assign({ to: "/hesabim/tjk/devam-edenler", className: openPage.includes("hesabim/tjk/devam-edenler") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: [_jsx("img", { src: iconFather, className: "tjkMenu" }, void 0), " TJK Kuponlar\u0131m"] }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/banka-hesaplarim", className: openPage.includes("hesabim/banka-hesaplarim") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Banka Hesaplar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/hesap-hareketlerim", className: openPage.includes("hesabim/hesap-hareketlerim") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Hesap Hareketlerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/destek-taleplerim/beklemede", className: openPage.includes("hesabim/destek-taleplerim") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Destek Taleplerim" }), void 0) }, void 0), _jsx("li", { children: _jsx("a", __assign({ onClick: function () {
                                            logout();
                                        }, style: { cursor: "pointer" } }, { children: "\u00C7\u0131k\u0131\u015F Yap" }), void 0) }, void 0)] }), void 0)] }), void 0), member && member.notication_count && Number(member.notication_count) > 0 ?
                    _jsxs("div", __assign({ className: "notification" }, { children: [_jsx("div", __assign({ onClick: function () {
                                    navigate('/bildirimler');
                                }, className: "badge cursor-pointer" }, { children: String(member.notication_count) }), void 0), _jsx(Link, __assign({ to: "/bildirimler" }, { children: _jsx("img", { src: IconBell, style: { filter: 'invert(1)', width: 20 }, className: "IconBell" }, void 0) }), void 0)] }), void 0) : null, _jsx("div", __assign({ className: "bonus" }, { children: _jsx("a", __assign({ onClick: function () {
                            openBonusModal();
                        } }, { children: _jsx("img", { src: bonusChest }, void 0) }), void 0) }), void 0)] }), void 0));
    }
    return (_jsxs("div", __assign({ className: "profile float-right pr-3" }, { children: [member.photo ? _jsx("img", { className: "avatar hidden-sm", src: env.core_api + "/" + member.photo, alt: "" }, void 0) :
                _jsx("img", { className: "avatar hidden-sm", src: DefaultAvatar, alt: "" }, void 0), _jsxs("div", __assign({ className: "member-info", onMouseOver: function () { return menuSelected(true); }, onMouseOut: function () { return menuSelected(false); } }, { children: [_jsxs("div", __assign({ style: { color: 'var(--color-text-dark)' } }, { children: [_jsx("span", __assign({ id: "copy", className: "copy-tooltip-navbar " }, { children: "Kopyaland\u0131" }), void 0), "\u00DCye No: ", member.id, " \u00A0", _jsx("img", { src: Copy, alt: "copy", className: "cursor-pointer invert-color", id: "copy-icon", onClick: function () { return copyMemberId(); } }, void 0)] }), void 0), _jsxs("div", __assign({ className: "balance" }, { children: [_jsx("span", { children: "Bakiye:" }, void 0), " ", member.getAvailableBalance().toFixed(2), "\u20BA"] }), void 0), _jsxs("ul", __assign({ className: 'profile-ul', id: "profile-menu", onClick: function () { return menuSelected(false); } }, { children: [_jsx("li", { children: _jsxs("div", { children: ["TL Bakiye ", _jsxs("span", __assign({ className: "float-right fw-bold" }, { children: [parseFloat(member.debit_balance).toFixed(2), " \u20BA"] }), void 0)] }, void 0) }, void 0), _jsx("li", __assign({ style: { borderBottom: "1px solid var(--color-text-medium)", paddingBottom: '5px' } }, { children: _jsxs("div", { children: ["OP Bakiye", _jsxs("span", __assign({ className: "float-right fw-bold" }, { children: [(parseFloat(member.point_balance) / 100).toFixed(2), " \u20BA"] }), void 0)] }, void 0) }), void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/uyelik", className: openPage.includes("hesabim/uyelik") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "\u00DCyelik Bilgilerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/para-yatir", className: openPage.includes("hesabim/para-yatir") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Para Yat\u0131r" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/para-cek", className: openPage.includes("hesabim/para-cek") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Para \u00C7ek" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/iddaa/devam-edenler", className: openPage.includes("hesabim/iddaa/devam-edenler") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "\u0130ddaa Kuponlar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/spor-toto/devam-edenler", className: openPage.includes("hesabim/spor-toto/devam-edenler") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Spor Toto Kuponlar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/esya-piyangosu/devam-eden-cekilisler", className: openPage.includes("hesabim/esya-piyangosu/devam-eden-cekilisler") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Piyango Biletlerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/oyna-kazan/kartlarim", className: openPage.includes("hesabim/oyna-kazan/kartlarim") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Oyna Kazan Kartlar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx("a", __assign({ onClick: function () {
                                        openBonusModal();
                                    }, style: { cursor: "pointer" } }, { children: "Puan Sepeti" }), void 0) }, void 0), env.tjk && _jsx("li", { children: _jsxs(Link, __assign({ to: "/hesabim/tjk/devam-edenler", className: openPage.includes("hesabim/tjk/devam-edenler") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: [_jsx("img", { src: iconFather, className: "tjkMenu" }, void 0), " TJK Kuponlar\u0131m"] }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/banka-hesaplarim", className: openPage.includes("hesabim/banka-hesaplarim") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Banka Hesaplar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/hesap-hareketlerim", className: openPage.includes("hesabim/hesap-hareketlerim") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Hesap Hareketlerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/destek-taleplerim/beklemede", className: openPage.includes("hesabim/destek-taleplerim") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Destek Taleplerim" }), void 0) }, void 0), _jsx("li", { children: _jsx("a", __assign({ onClick: function () {
                                        logout();
                                    }, style: { cursor: "pointer" } }, { children: "\u00C7\u0131k\u0131\u015F Yap" }), void 0) }, void 0)] }), void 0)] }), void 0), member && member.notication_count && Number(member.notication_count) > 0 ? _jsxs("div", __assign({ className: "notification" }, { children: [_jsx("div", __assign({ onClick: function () {
                            navigate('/bildirimler');
                        }, className: "badge cursor-pointer" }, { children: String(member.notication_count) }), void 0), _jsx(Link, __assign({ to: "/bildirimler" }, { children: _jsx("img", { src: IconBell, className: "IconBell invert-color" }, void 0) }), void 0)] }), void 0)
                : null, _jsx("div", __assign({ className: "bonus" }, { children: _jsx("a", __assign({ onClick: function () {
                        openBonusModal();
                    } }, { children: _jsx("img", { src: bonusChest }, void 0) }), void 0) }), void 0)] }), void 0));
}
export default Profile;
