var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getCoupons, getEvents, getMemberPhotos, getPosts, } from "../../../services/sosyoley.services";
import _ from "lodash";
import { fillEventStatistics } from "../../../services/sportsbook.services";
import { sosyoleyEventIdMapping } from "../../sosyoley/subpages/posts";
import { currencyFormat, dateTimeFormat, useForceUpdate } from "../../../lib/misc.functions";
import { env } from "../../../definitions/global.vars";
import DefaultAvatar from "../../../assets/img/avatar.png";
import Modal from "react-modal";
import ExitIcon from "../../../assets/feather/x-circle.svg";
import PostComponent from "../../sosyoley/components/post";
import { CommentWidget } from "../../sosyoley/components/comments";
var refreshTimer = null;
var Title = function () {
    return (_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-left" }, { children: "Kullan\u0131c\u0131" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Zaman" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Oranlar" }), void 0), _jsx("th", __assign({ className: "text-right" }, { children: "Kazan\u00E7" }), void 0)] }, void 0) }, void 0));
};
export default function SosyoleyWidget() {
    var _a = useState([]), posts = _a[0], setPosts = _a[1];
    var _b = useState(null), openDetail = _b[0], setOpenDetail = _b[1];
    var forceUpdate = useForceUpdate();
    var _c = useState(false), postCommentModal = _c[0], setPostCommentModal = _c[1];
    useEffect(function () {
        getFirstPage();
        return function () {
            setPosts([]);
        };
    }, []);
    var getFirstPage = function (isFirst) {
        if (isFirst === void 0) { isFirst = true; }
        getPosts('/misc/api/get-posts/', {
            ordering: '-create_date',
            content_type: 2,
            only_playable: true,
            page: 0,
        }).then(function (response) {
            // console.log("response", response)
            if (response && response !== undefined) {
                var uniqeMemberId = _.uniq(_.map(response, 'member_id'));
                // console.log(uniqeMemberId)
                getMemberPhotos(uniqeMemberId).then(function (members) {
                    if (members && members.status) {
                        response.forEach(function (item) {
                            item.member = members.data.find(function (member) { return (member.member_id === item.member_id); });
                        });
                    }
                    var couponIds = response.filter(function (el) { return el.content_type === 2; }).map(function (el) {
                        return el.obj_id;
                    });
                    var eventList = response.filter(function (el) { return el.content_type === 1; }).map(function (el) {
                        return el.obj_id;
                    });
                    // console.log(couponIds)
                    if (couponIds.length > 0) {
                        getCoupons(couponIds).then(function (coupons) {
                            coupons.forEach(function (c) {
                                if (c.slip_rows) {
                                    eventList.push.apply(eventList, eventList.concat(c.slip_rows.map(function (r) { return r.event_id; })));
                                }
                            });
                            response.forEach(function (item) {
                                item.coupon = coupons.find(function (coupon) { return (coupon.coupon_id === item.obj_id); });
                            });
                            var uniqeEventId = _.uniq(eventList);
                            if (uniqeEventId.length > 0) {
                                getEvents(uniqeEventId).then(function (events) {
                                    for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
                                        var event_1 = events_1[_i];
                                        sosyoleyEventIdMapping[Number(event_1.eventId)] = event_1;
                                    }
                                    // console.log("sosyoley fillEventStatistics >>>", uniqeEventId)
                                    fillEventStatistics(uniqeEventId).then(function () {
                                        var loading = document.getElementById('innerLoading');
                                        if (loading)
                                            loading.style.display = 'none';
                                        setPosts(response);
                                    }).catch(function () {
                                        var loading = document.getElementById('innerLoading');
                                        if (loading)
                                            loading.style.display = 'none';
                                        setPosts(response);
                                    });
                                });
                            }
                            else {
                                var loading = document.getElementById('innerLoading');
                                if (loading)
                                    loading.style.display = 'none';
                                setPosts(response);
                            }
                        });
                    }
                    else {
                        var uniqeEventId = _.uniq(response.map(function (el) {
                            return el.obj_id;
                        }));
                        if (uniqeEventId.length > 0) {
                            getEvents(uniqeEventId).then(function (events) {
                                for (var _i = 0, events_2 = events; _i < events_2.length; _i++) {
                                    var event_2 = events_2[_i];
                                    sosyoleyEventIdMapping[Number(event_2.eventId)] = event_2;
                                }
                                setPosts(response);
                            });
                        }
                        else {
                            setPosts(response);
                        }
                    }
                });
            }
        });
    };
    useEffect(function () {
        refreshTimer = setInterval(function () {
            getFirstPage();
        }, 5000);
        return function () {
            clearInterval(refreshTimer);
        };
    }, []);
    // if (posts === null || posts.length === 0) {
    //     const loading = document.getElementById('innerLoading')
    //     if (loading) loading.style.display = 'unset';
    //
    //     setTimeout(() => {
    //         const loading = document.getElementById('innerLoading')
    //         if (loading) loading.style.display = 'none';
    //     }, 1000);
    //     if (posts === null) {
    //         return <></>
    //     }
    // }
    return (_jsxs("div", __assign({ className: "widget card ml-1 mr-1 sosyoley-widget" }, { children: [_jsx("div", __assign({ className: "header" }, { children: _jsx("h2", { children: "Son Kuponlar" }, void 0) }), void 0), _jsxs("div", __assign({ className: "body p-2" }, { children: [_jsxs("table", __assign({ className: "table table-striped" }, { children: [_jsx(Title, {}, void 0), _jsx("tbody", { children: posts.map(function (post, key) {
                                    return (_jsxs("tr", __assign({ className: "cursor-pointer", onClick: function () {
                                            setOpenDetail(post);
                                        } }, { children: [_jsx("td", __assign({ className: "text-left" }, { children: post.member ?
                                                    _jsxs("div", __assign({ className: "member" }, { children: [post.member.photo ? _jsx("img", { className: "avatar", src: "".concat(env.core_api).concat(post.member.photo), width: 21 }, void 0) :
                                                                _jsx("img", { className: "avatar", src: DefaultAvatar, width: 21 }, void 0), _jsxs("div", __assign({ className: post.coupon ? 'align-items-center nickname' : "nickname", style: { height: "100%" } }, { children: [_jsxs("a", { children: ["@", post.member.nick_name] }, void 0), _jsx("br", {}, void 0)] }), void 0)] }), void 0) : _jsx(_Fragment, {}, void 0) }), void 0), _jsx("td", __assign({ className: "text-center" }, { children: dateTimeFormat(post.coupon.approve_date).split(' ')[1] }), void 0), _jsx("td", __assign({ className: "text-center" }, { children: post.coupon.total_ratio }), void 0), _jsxs("td", __assign({ className: "text-right" }, { children: [currencyFormat(post.coupon.profit), "\u20BA"] }), void 0)] }), "sosyoleyWidget" + key));
                                }) }, void 0)] }), void 0), _jsxs(Modal, __assign({ isOpen: openDetail ? true : false, onRequestClose: function () { return setOpenDetail(null); }, style: {
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                maxWidth: 'calc(100vw - 40px)',
                                width: '920px',
                                padding: '0px',
                                maxHeight: 'calc(100vh - 100px)',
                                transform: 'translate(-50%, -50%)',
                                background: 'var(--color-bg-white)',
                            },
                        }, contentLabel: "" }, { children: [_jsx("div", __assign({ className: "card-title p-2" }, { children: _jsx("div", __assign({ className: "float-right" }, { children: _jsx("img", { src: ExitIcon, width: 24, className: "float-right cursor-p invert-color", onClick: function () { return setOpenDetail(null); } }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "page sosyoley" }, { children: _jsx("div", __assign({ className: "post" }, { children: _jsx(PostComponent, { post: openDetail, posts: posts, modalState: {
                                            postCommentModal: postCommentModal,
                                            setPostCommentModal: setPostCommentModal
                                        }, forceUpdate: forceUpdate }, void 0) }), void 0) }), void 0)] }), void 0), _jsx(CommentWidget, { forceUpdate: forceUpdate, modalState: { postCommentModal: postCommentModal, setPostCommentModal: setPostCommentModal } }, void 0)] }), void 0)] }), void 0));
}
