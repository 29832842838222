var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./header.css";
import Login from "../widgets/login";
import { useAuthContext, member } from "../../store/login.store";
import logo from '../../assets/img/oley-logo-blue.png';
import Navbar from "./components/navbar/navbar";
import Profile from "./components/profile/profile";
import { Link } from "react-router-dom";
import AlignLeft from "../../assets/feather/align-left.svg";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { toggleMobileSideMenu } from "../../lib/misc.functions";
import { eventDetailSubject } from "../../store/sportsbook.store";
// @ts-ignore
// const CounterAppOne = React.lazy(() => import("app1/CounterAppOne"));
export default function Header() {
    var isAuthenticated = useAuthContext().isAuthenticated;
    return (_jsx("div", __assign({ className: "header-wrapper" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col-lg-2 col-md-2 col-sm-5 mb-0 mt-md-3 mt-8 pr-md-0" }, { children: [_jsx("div", __assign({ className: "float-left left-menu-toggle", onClick: function () {
                                betSlipVisibleSubject.next(false);
                                eventDetailSubject.next('toggle');
                                toggleMobileSideMenu();
                            } }, { children: _jsx("img", { className: "ico", src: AlignLeft, alt: "" }, void 0) }), void 0), _jsx(Link, __assign({ to: "/", onClick: function () { betSlipVisibleSubject.next(false); } }, { children: _jsx("img", { className: "branding", src: logo, alt: "Oley.com" }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "col-lg-7 col-md-7 navbar-col mb-0 mt-0" }, { children: _jsx(Navbar, {}, void 0) }), void 0), _jsx("div", __assign({ className: "col-lg-3 col-md-3 col-sm-7 profile-column mb-0" }, { children: isAuthenticated ? _jsx(Profile, { member: member }, void 0) : _jsx(Login, {}, void 0) }), void 0)] }), void 0) }), void 0));
}
