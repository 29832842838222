var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import { RegisterApi } from '../register.api';
import { loginMember } from "../../../store/login.store";
import { customStyles } from "../../../definitions/constants/modal.constant";
import { modalServiceSubject } from "../../../components/modal/modal";
import { callLoginApi } from "../../../services/login.services";
import { dataLayerPushEvent } from "../../../services/push.services";
function ValidationSmsModal(props) {
    function closeModal() {
        props.setIsOpen(false);
    }
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, errors = _a.formState.errors;
    var onSubmit = function (data) {
        codeMobileApi(data);
    };
    var codeMobileApi = function (data) {
        data.mobile = loginMember.mobile;
        data.type = "register_validate";
        data.token = null;
        RegisterApi.validation_sms(data).then(function (result) {
            if (result.status) {
                props.setIsOpen(false);
                callLoginApi(loginMember.tc_id, loginMember.password);
                dataLayerPushEvent('sign_up', result.data);
            }
            else {
                if (result.message != 'resend') {
                }
                else {
                    modalServiceSubject.next({
                        title: "Hata!",
                        content: result.message,
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0
                    });
                }
            }
        });
    };
    if (!props.modalIsOpen) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsx(_Fragment, { children: _jsx(Modal, __assign({ isOpen: props.modalIsOpen, onRequestClose: closeModal, shouldCloseOnOverlayClick: false, shouldCloseOnEsc: false, shouldReturnFocusAfterClose: false, style: customStyles, contentLabel: "" }, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsx("h3", { children: "Telefon Do\u011Frulama" }, void 0), _jsxs("label", { children: ["L\u00FCtfen ", loginMember.mobile, " numaras\u0131na g\u00F6nderilen kodu girip telefon numaran\u0131z\u0131 onaylay\u0131n\u0131z.", props.modalIsOpen ? _jsx(_Fragment, { children: _jsx("div", { children: _jsx(CountDown, { timer: 240 }, void 0) }, void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }, void 0), _jsx("div", __assign({ className: "card-body p-0" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", { children: [_jsx("input", __assign({ placeholder: "Doğrulama Kodu" }, register('code', { required: true }), { className: "form-control" }), void 0), errors.code && errors.code.type === "required" &&
                                            _jsx("span", __assign({ className: "text-validation" }, { children: "L\u00FCtfen do\u011Frulama kodu giriniz." }), void 0)] }, void 0), _jsx("input", { className: "btn btn-primary btn-large mt-3", type: "submit", value: "DO\u011ERULA" }, void 0)] }), void 0) }), void 0)] }), void 0) }), void 0) }, void 0));
}
export default ValidationSmsModal;
function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    var obj = {
        "h": String(hours).padStart(2, '0'),
        "m": String(minutes).padStart(2, '0'),
        "s": String(seconds).padStart(2, '0')
    };
    return obj;
}
var timerRegisterCountDown;
export function CountDown(props) {
    var timer = props.timer;
    var _a = useState(timer), timeLeft = _a[0], setTimeLeft = _a[1];
    useEffect(function () {
        if (timeLeft > 0) {
            timerRegisterCountDown = setTimeout(function () {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        }
        return function () {
            clearTimeout(timerRegisterCountDown);
        };
    }, [timeLeft]);
    if (timeLeft <= 0) {
        return (_jsxs(_Fragment, { children: ["S\u00FCre doldu! tekrar do\u011Frulama kodu g\u00F6ndermeniz gerekiyor. ", _jsx("a", __assign({ onClick: function () {
                        RegisterApi.validation_sms({
                            mobile: loginMember.mobile,
                            type: "register_send",
                            token: null,
                        }).then(function () { setTimeLeft(timer); });
                    }, style: { cursor: 'pointer', fontWeight: 'bold', color: 'var(--color-danger)' } }, { children: "Tekrar G\u00F6nder" }), void 0), " "] }, void 0));
    }
    return (_jsx(_Fragment, { children: _jsxs("span", { children: [_jsxs("b", { children: [secondsToTime(timeLeft).m, ":", secondsToTime(timeLeft).s] }, void 0), " saniye sonra yeniden kod g\u00F6nderebilirsiniz."] }, void 0) }, void 0));
}
