var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import './bonus.css';
import { env } from "../../../../definitions/global.vars";
import { ApiService } from "../../../../lib/http.service";
import { Storage } from "../../../../lib/localstorege.service";
import InfiniteScroll from "react-infinite-scroll-component";
import { currencyFormatWithFix, dateTimeStringFormat } from "../../../../lib/misc.functions";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { refreshMemberInfo } from "../../../../services/login.services";
import iconSadFace from "../../../../assets/img/sad_face.png";
import paths from "../../../../app/paths";
import { confettiSubject } from "../../../../store/login.store";
import CheckCircle from "../../../../assets/feather/check-circle.svg";
import ExitIcon from "../../../../assets/feather/x-circle.svg";
var width = window.innerWidth;
var isMobileView = width < 986 ? true : false;
export default function Bonus() {
    var _a = useState(0), selectedTab = _a[0], setSelectedTab = _a[1];
    var _b = useState([]), bonuses = _b[0], setBonuses = _b[1];
    var _c = useState([]), usedBonuses = _c[0], setUsedBonuses = _c[1];
    var _d = useState(0), totalAvailableBonus = _d[0], setTotalAvailableBonus = _d[1];
    var _e = useState(true), hasMore = _e[0], setHasMore = _e[1];
    var _f = useState(true), usedHasMore = _f[0], setUsedHasMore = _f[1];
    var _g = useState(1), page = _g[0], setPage = _g[1];
    var collectBonuses = function () {
        var api = new ApiService();
        api.start('post', env.accounting_api + '/accounting/api/bonus/use/', { token: Storage.get('token') }, true)
            .then(function (response) {
            confettiSubject.next(true);
            getFirstPage();
            modalServiceSubject.next({
                show: true,
                footerCenter: true,
                content: '<div class="p-3"><p class="text-center">' +
                    '<img class="mb-2 w-25" src="' + CheckCircle + '" /><br />Puanlarınız hesabınıza aktarıldı. Hemen kullanmaya başlayabilirsiniz</p></div>',
                confirm: {
                    class: 'w-50',
                    cancel: { label: 'Tamam' }
                },
                timeout: 0
            });
            refreshMemberInfo();
        });
    };
    var getFirstPage = function () {
        setBonuses([]);
        setUsedBonuses([]);
        setTotalAvailableBonus(0);
        var api = new ApiService();
        // if (selectedTab == 0) {
        var payload = {
            token: Storage.get('token'),
        };
        if (selectedTab == 1) {
            payload['get_used_bonus'] = 1;
            payload['get_available_bonus'] = 0;
        }
        api.start('post', env.accounting_api + '/accounting/api/bonus/get/', payload, true)
            .then(function (response) {
            if (selectedTab == 0) {
                if (response.data.bonus_list.list.length === 0 || response.data.bonus_list.list.length < 20) {
                    setHasMore(false);
                    console.log('HEEREEEEE');
                }
                setBonuses(response.data.bonus_list.list);
                setTotalAvailableBonus(response.data.available_bonus);
            }
            else {
                if (response.data.bonus_list.list.length === 0 || response.data.bonus_list.list.length < 20) {
                    setUsedHasMore(false);
                }
                setUsedBonuses(response.data.bonus_list.list);
            }
        });
        // } else {
        //     api.start('post', env.accounting_api + '/accounting/api/bonus/use/', {token: Storage.get('token')}, true)
        //         .then((response: any) => {
        //             setUsedBonuses(response.data.bonus_list.list);
        //         });
        // }
    };
    var fetchMoreData = function () {
        // setHasMore(false);
        // setUsedHasMore(false);
        var api = new ApiService();
        var payload = {
            token: Storage.get('token'),
            page: page
        };
        if (selectedTab == 1) {
            payload['get_used_bonus'] = 1;
            payload['get_available_bonus'] = 0;
        }
        api.start('post', env.accounting_api + '/accounting/api/bonus/get/', payload, true)
            .then(function (response) {
            if (selectedTab == 0) {
                if (response.data.bonus_list.list.length === 0 || response.data.bonus_list.list.length < 20) {
                    setHasMore(false);
                }
                setBonuses(__spreadArray(__spreadArray([], bonuses, true), response.data.bonus_list.list, true));
            }
            else {
                if (response.data.bonus_list.list.length === 0 || response.data.bonus_list.list.length < 20) {
                    setUsedHasMore(false);
                }
                setUsedBonuses(__spreadArray(__spreadArray([], usedBonuses, true), response.data.bonus_list.list, true));
            }
        });
        // } else {
        //     api.start('post', env.accounting_api + '/accounting/api/bonus/use/', {
        //         token: Storage.get('token'),
        //         page: page
        //     }, true)
        //         .then((response: any) => {
        //             if (response.length === 0 || response.length < 20) {
        //                 setUsedHasMore(false)
        //             }
        //
        //             setUsedBonuses([...usedBonuses, ...response.data.bonus_list.list]);
        //
        //         });
        // }
        setPage(page + 1);
    };
    // useEffect(() => {
    //     const api = new ApiService();
    //     api.start('post', env.accounting_api + '/accounting/api/test-bonus/', {token: Storage.get('token')}, true)
    //         .then((response: any) => {
    //         });
    // }, []);
    useEffect(function () {
        getFirstPage();
    }, [selectedTab]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "bonus-cart" }, { children: [_jsx("div", __assign({ className: "wrapper" }, { children: _jsxs("h1", { children: ["PUAN SEPET\u0130M", _jsx("span", { children: "Toplad\u0131\u011F\u0131n puanlar\u0131 kullan, \u015Fans\u0131n\u0131 yakala" }, void 0)] }, void 0) }), void 0), _jsx("div", __assign({ className: "", style: { position: "absolute", right: "10px", top: "35px" } }, { children: _jsx("img", { src: ExitIcon, width: 24, className: "float-right cursor-p", onClick: function () {
                                modalServiceSubject.next(false);
                            } }, void 0) }), void 0)] }), void 0), _jsx("div", { children: _jsxs("p", __assign({ className: "bonus-cart-description" }, { children: [_jsx("b", { children: "Oley Puan nedir?" }, void 0), _jsx("br", {}, void 0), "Oley Puan, Oley.com'da oynad\u0131k\u00E7a kazand\u0131\u011F\u0131n puanlard\u0131r. Oley Puanlar\u0131n\u0131z\u0131 kullanarak bahis yapabilir kazanc\u0131n\u0131z\u0131 artt\u0131rabilirsiniz. Unutma, kullanmad\u0131\u011F\u0131n puan ", _jsx("b", { children: "7 g\u00FCn i\u00E7erisinde silinir" }, void 0), ". Puan ve puan kullan\u0131m\u0131 hakk\u0131nda daha fazla bilgi almak i\u00E7in ", _jsx("a", __assign({ href: "/yardim/iddaa" }, { children: "t\u0131klay\u0131n\u0131z." }), void 0)] }), void 0) }, void 0), _jsxs("div", __assign({ className: "bonus-cart-body" }, { children: [_jsxs("div", __assign({ className: "actions" }, { children: [_jsx("a", __assign({ onClick: function () {
                                    setSelectedTab(0);
                                }, className: "tab " + (selectedTab === 0 ? 'active' : '') }, { children: "Kullan\u0131labilir Puanlar" }), void 0), _jsx("a", __assign({ onClick: function () {
                                    setSelectedTab(1);
                                }, className: "tab " + (selectedTab === 1 ? 'active' : '') }, { children: "Ge\u00E7mi\u015F Puanlar" }), void 0)] }), void 0), selectedTab == 0 &&
                        _jsxs("div", __assign({ className: "p-1" }, { children: [bonuses.length > 0 && _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row mt-2 mb-2" }, { children: [_jsxs("div", __assign({ className: "col-6" }, { children: ["Toplanabilir Puan\u0131n\u0131z: ", _jsxs("span", __assign({ className: "fw-bold" }, { children: [" ", currencyFormatWithFix(Number(totalAvailableBonus), 0), " OP"] }), void 0)] }), void 0), _jsx("div", __assign({ className: "col-6 text-right" }, { children: _jsx("button", __assign({ onClick: function () {
                                                        collectBonuses();
                                                    }, className: "btn btn-primary m-0" }, { children: isMobileView ? "Hesabıma Aktar" : "Puanlarımı Hesabıma Aktar" }), void 0) }), void 0)] }), void 0) }, void 0), bonuses.length == 0 && _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col-12 mt-5 mb-5 text-center" }, { children: [_jsx("img", { src: iconSadFace, style: { width: 96, opacity: 0.4 } }, void 0), _jsx("p", __assign({ className: "d-block" }, { children: "\u015Eu anda sepetinizde hi\u00E7 puan\u0131n\u0131z yok!" }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-12 text-left" }, { children: [_jsxs("p", { children: [_jsx("b", { children: "Nas\u0131l Puan toplar\u0131m?" }, void 0), _jsx("br", {}, void 0), "G\u00FCncel kampanyalar\u0131m\u0131za kat\u0131larak ve oynad\u0131k\u00E7a kazanarak puan toplayabilirsiniz. Yapt\u0131\u011F\u0131n\u0131z y\u00FCksek oran kuponlar\u0131 ve oynad\u0131\u011F\u0131n\u0131z miktarlar do\u011Frultusunda puanlar\u0131n\u0131z otomatik olarak puan sepetinizde toplan\u0131r. Daha fazla detay i\u00E7in ", _jsx("a", __assign({ href: paths.CAMPAIGNS }, { children: "kampanyalar\u0131m\u0131z\u0131" }), void 0), " inceleyebilirsiniz."] }, void 0), _jsxs("p", { children: [_jsx("b", { children: "Puanlar\u0131n\u0131z\u0131 nas\u0131l kullanabilirim?" }, void 0), _jsx("br", {}, void 0), "Puan sepetinizde biriken puanlar\u0131n\u0131z\u0131 hesab\u0131n\u0131za aktarabilir ve aktard\u0131\u011F\u0131n\u0131z Oley Puanlar\u0131n\u0131z ile bahis yapabilir ya da \u00E7ekili\u015Flere kat\u0131labilirsiniz.", _jsx("br", {}, void 0), _jsx("br", {}, void 0), "100 OP = 1 TL de\u011Ferindedir."] }, void 0)] }), void 0)] }), void 0) }, void 0), bonuses.length > 0 &&
                                    _jsx("div", __assign({ id: "scrollableDiv", style: {
                                            height: 300,
                                            overflow: 'auto',
                                        }, className: "p-1" }, { children: _jsx(InfiniteScroll, __assign({ dataLength: bonuses.length, next: fetchMoreData, hasMore: hasMore, scrollableTarget: "scrollableDiv", loader: hasMore ? _jsx("div", __assign({ className: "p-5" }, { children: _jsxs("div", __assign({ className: "lds-roller" }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0) }), void 0) : _jsx(_Fragment, {}, void 0), endMessage: _jsx(_Fragment, {}, void 0) }, { children: _jsx("div", { children: bonuses.length > 0 ? bonuses.map(function (item, key) {
                                                    return (_jsxs("div", __assign({ className: "bonus-card w-100" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between" }, { children: [_jsx("h4", { children: item.reason_display }, void 0), _jsxs("span", __assign({ className: "fw-bold" }, { children: [currencyFormatWithFix(Number(item.amount), 0), " OP"] }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between" }, { children: [_jsx("span", { children: item.description }, void 0), _jsxs("span", __assign({ className: "text-right" }, { children: ["Son Kullan\u0131m Tarihi: ", _jsx("br", {}, void 0), dateTimeStringFormat(item.expire_date)] }), void 0)] }), void 0) }), void 0)] }), key + 'bonus'));
                                                }) : null }, void 0) }), void 0) }), void 0)] }), void 0), selectedTab == 1 && _jsxs("div", __assign({ className: "p-1" }, { children: ["\u00D6nceden kulland\u0131\u011F\u0131n\u0131z veya s\u00FCresi ge\u00E7en puanlar\u0131n\u0131z a\u015Fa\u011F\u0131da listelenmektedir.", usedBonuses.length == 0 && _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col-12 mt-5 mb-5 text-center" }, { children: [_jsx("img", { src: iconSadFace, style: { width: 96, opacity: 0.4 } }, void 0), _jsx("p", __assign({ className: "d-block" }, { children: "\u015Eu anda sepetinizde s\u00FCresi ge\u00E7mi\u015F veya kullan\u0131lm\u0131\u015F hi\u00E7 puan\u0131n\u0131z yok!" }), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-12 text-left" }, { children: [_jsxs("p", { children: [_jsx("b", { children: "Nas\u0131l Puan toplar\u0131m?" }, void 0), _jsx("br", {}, void 0), "G\u00FCncel kampanyalar\u0131m\u0131za kat\u0131larak ve oynad\u0131k\u00E7a kazanarak puan toplayabilirsiniz. Yapt\u0131\u011F\u0131n\u0131z y\u00FCksek oran kuponlar\u0131 ve oynad\u0131\u011F\u0131n\u0131z miktarlar do\u011Frultusunda puanlar\u0131n\u0131z otomatik olarak puan sepetinizde toplan\u0131r. daha fazla detay i\u00E7in ", _jsx("a", __assign({ href: paths.CAMPAIGNS }, { children: "kampanyalar\u0131m\u0131z\u0131" }), void 0), " inceleyebilirsiniz."] }, void 0), _jsxs("p", { children: [_jsx("b", { children: "Puanlar\u0131n\u0131z\u0131 nas\u0131l kullanabilirim?" }, void 0), _jsx("br", {}, void 0), "Puan sepetinizde biriken puanlar\u0131n\u0131z\u0131 hesab\u0131n\u0131za aktarabilir ve aktard\u0131\u011F\u0131n\u0131z Oley Puanlar\u0131n\u0131z ile bahis yapabilir ya da \u00E7ekili\u015Flere kat\u0131labilirsiniz.", _jsx("br", {}, void 0), _jsx("br", {}, void 0), "100 OP = 1 TL de\u011Ferindedir."] }, void 0)] }), void 0)] }), void 0) }, void 0), usedBonuses.length > 0 &&
                                _jsx("div", __assign({ id: "scrollableDiv", style: {
                                        height: 300,
                                        overflow: 'auto',
                                    }, className: "p-1" }, { children: _jsx(InfiniteScroll, __assign({ dataLength: usedBonuses.length, next: fetchMoreData, hasMore: usedHasMore, scrollableTarget: "scrollableDiv", loader: usedHasMore ? _jsx("div", __assign({ className: "p-5" }, { children: _jsxs("div", __assign({ className: "lds-roller" }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0) }), void 0) : _jsx(_Fragment, {}, void 0), endMessage: _jsx(_Fragment, {}, void 0) }, { children: _jsx("div", { children: usedBonuses.length > 0 ? usedBonuses.map(function (item, key) {
                                                return (_jsxs("div", __assign({ className: "bonus-card w-100" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between" }, { children: [_jsx("h4", { children: item.reason_display }, void 0), _jsxs("span", __assign({ className: "fw-bold" }, { children: [currencyFormatWithFix(Number(item.amount), 0), " OP"] }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between" }, { children: [_jsx("span", { children: item.description }, void 0), _jsxs("span", __assign({ className: "text-right" }, { children: ["Kullan\u0131m Tarihi: ", _jsx("br", {}, void 0), dateTimeStringFormat(item.used_date)] }), void 0)] }), void 0) }), void 0)] }), key + 'bonus'));
                                            }) : null }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }, void 0));
}
