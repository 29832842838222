var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Api, LoginApi } from '../shared/login.api';
import { Storage } from "../lib/localstorege.service";
import { AuthWSProvider, AuthWS } from "./websocket.services";
import { Member } from "../models/user.model";
import { member } from "../store/login.store";
import { env } from "../definitions/global.vars";
import { modalServiceSubject } from "../components/modal/modal";
import Alert from "../assets/feather/alert-triangle.svg";
import { ApiService } from "../lib/http.service";
import { staticPagesList } from "../store/static.pages.store";
import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { showAgreementModalForLogin, useForceUpdate } from "../lib/misc.functions";
import { dataLayerPushEvent } from "./push.services";
var BASE_PATH = env.core_api;
export var headers = function (accept) { return ({
    Accept: accept,
    "Content-Type": "application/x-www-form-urlencoded"
}); };
Api.setbaseUrl(BASE_PATH);
Api.setHeaders(headers('application/json'));
export var loginApi = LoginApi;
export function doLogin(token) {
    Storage.set('token', token);
    AuthWS.connect(AuthWS.AUTH, token);
    var api = new ApiService();
    api.start('post', env.core_api + '/marketing/api/available-bonus/', Storage.get('token') ? { token: Storage.get('token') } : null, true, 3000)
        .then(function (res) {
        Storage.set('cached_available_bonus', res.data.available_bonus, 60 * 1000);
    });
}
export function doLogout() {
    Storage.del('token');
    Storage.del('cached_available_bonus');
    Object.assign(member, new Member());
}
export function refreshMemberInfo() {
    if (AuthWSProvider && AuthWSProvider.readyState === 1) {
        AuthWSProvider.send(JSON.stringify({
            type: {
                $type: "getAccountInfo",
                data: ''
            }
        }));
    }
}
export function restoreLogin() {
    var exToken = Storage.get('token');
    //console.log("restore login--->>>", exToken)
    if (exToken !== null) {
        doLogin(exToken);
    }
}
export var callChangePhone = function (username, password, mobile) {
    var payload = {
        username: username,
        password: password,
        mobile: mobile.replaceAll(' ', '')
    };
    var api = new ApiService();
    api.start('post', env.auth_api + '/member/api/change-mobile/', payload, true, 3000)
        .then(function (res) {
        if (res.status) {
            res.data.verify_message = null;
            modalServiceSubject.next({
                title: "Hesap Aktivasyonu",
                // width: '700px',
                height: 'auto',
                content: _jsx(MobileValidationModal, { result: res, password: password, username: username }, void 0),
                confirm: {
                    sure: {
                        label: 'Numaramı Doğrula',
                        class: 'btn btn-success',
                        action: function () {
                            var mobile_validation_code = document.getElementById("id_mobileValidationCode");
                            var mobile_change = document.getElementById("id_newMobile");
                            if (mobile_validation_code) {
                                callLoginApi(username, password, { mobile_validation_code: mobile_validation_code.value.replaceAll(' ', '') });
                            }
                            else if (mobile_change) {
                                callChangePhone(username, password, mobile_change.value);
                            }
                        }
                    },
                    cancel: {
                        label: 'Vazgeç',
                        action: function () {
                        }
                    }
                },
                timeout: 0
            });
        }
        else {
            modalServiceSubject.next({
                show: true,
                title: "Hatalı giriş!",
                content: '<div class="p-3"><p class="text-center"><img src="' + Alert + '" /><br />' + res.message + '</p></div>',
                confirm: {
                    cancel: { label: 'Tamam' }
                },
                timeout: 0
            });
        }
    });
};
export var callLoginApi = function (username, password, option) {
    if (option === void 0) { option = null; }
    loginApi.login({ username: username, password: password, option: option }).then(function (result) {
        if (result.status) {
            try {
                // @ts-ignore
                window.gtag('event', 'conversion', { 'send_to': 'AW-646617036/jCZJCLbn3-0YEMyvqrQC' });
            }
            catch (e) {
            }
            var token = result.data.token;
            loginApi.checkAouth({ token: token, renew_member_info: true });
            dataLayerPushEvent('login', member);
            doLogin(token);
        }
        else {
            // console.log("*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-")
            // console.log(result.data)
            if (result.data && result.data.hasOwnProperty('check_agrement') && Number(result.data.check_agrement) === 0) {
                var staticPagesData = staticPagesList.find(function (x) { return x.type === 0; });
                modalServiceSubject.next({
                    title: staticPagesData.name,
                    width: '700px',
                    height: 'calc(70vh - 96px)',
                    content: '<div class="p-1">' + staticPagesData.body + '</div>',
                    confirm: {
                        sure: {
                            label: 'Onaylıyorum',
                            action: function () {
                                callLoginApi(username, password, { is_checked: true });
                            }
                        },
                        cancel: {
                            label: 'Reddediyorum',
                            action: function () {
                            }
                        }
                    },
                    timeout: 0
                });
            }
            else if (result.data && result.data.hasOwnProperty('user_passive') && Number(result.data.user_passive) === 1) {
                modalServiceSubject.next({
                    title: "Hesap Aktivasyonu",
                    // width: '700px',
                    height: 'auto',
                    content: '<div class="p-1">Üyeliğiniz talebiniz üzerine kapatılmıştır. Tekrar açmak ister misiniz?</div>',
                    confirm: {
                        sure: {
                            label: 'Hesabımı Tekrar Aktif Et',
                            action: function () {
                                callLoginApi(username, password, { re_activate: true });
                            }
                        },
                        cancel: {
                            label: 'Vazgeç',
                            action: function () {
                            }
                        }
                    },
                    timeout: 0
                });
            }
            else if (result.data && result.data.validation_required && Number(result.data.validation_required) === 0) {
            }
            else if (result.data && result.data.hasOwnProperty('verify_mobile') && Number(result.data.verify_mobile) === 0) {
                modalServiceSubject.next({
                    title: "Hesap Aktivasyonu",
                    // width: '700px',
                    height: 'auto',
                    content: _jsx(MobileValidationModal, { result: result, password: password, username: username }, void 0),
                    confirm: {
                        sure: {
                            label: 'Numaramı Doğrula',
                            class: 'btn btn-success',
                            action: function () {
                                var mobile_validation_code = document.getElementById("id_mobileValidationCode");
                                var mobile_change = document.getElementById("id_newMobile");
                                if (mobile_validation_code) {
                                    callLoginApi(username, password, { mobile_validation_code: mobile_validation_code.value.replaceAll(' ', '') });
                                }
                                else if (mobile_change) {
                                    callChangePhone(username, password, mobile_change.value);
                                }
                            }
                        },
                        cancel: {
                            label: 'Vazgeç',
                            action: function () {
                            }
                        }
                    },
                    timeout: 0
                });
            }
            else if (result.data && result.data.hasOwnProperty('mandatory_agreements')) {
                var approvedItems = [];
                var agreements = [];
                var _loop_1 = function (agreementKey) {
                    if (result.data.mandatory_agreements[agreementKey] === 0) {
                        var staticPagesData = staticPagesList.find(function (x) { return x.type.toString() === agreementKey.toString(); });
                        agreements.push(staticPagesData);
                    }
                };
                for (var agreementKey in result.data.mandatory_agreements) {
                    _loop_1(agreementKey);
                }
                showAgreementModalForLogin(username, password, agreements, approvedItems);
            }
            else {
                modalServiceSubject.next({
                    show: true,
                    title: "Hatalı giriş!",
                    content: '<div class="p-3"><p class="text-center"><img src="' + Alert + '" /><br />' + result.message + '</p></div>',
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        }
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = false;
        }
    }).catch(function (e) {
        console.log(e);
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = false;
        }
    }).finally(function () {
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = false;
        }
    });
};
export function MobileValidationModal(prop) {
    var _a, _b;
    var _c = useState(false), showChangePhone = _c[0], setShowChangePhone = _c[1];
    var result = prop.result;
    var username = prop.username;
    var password = prop.password;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "p-1" }, { children: [((_a = result.data) === null || _a === void 0 ? void 0 : _a.verify_message) &&
                    _jsx("p", __assign({ className: "py-3 alert alert-danger text-center" }, { children: (_b = result.data) === null || _b === void 0 ? void 0 : _b.verify_message }), void 0), !showChangePhone && _jsxs(_Fragment, { children: [" Telefon numaran\u0131z\u0131 do\u011Frulamal\u0131s\u0131n\u0131z, do\u011Frulanm\u0131\u015F bir telefon numaran\u0131z olmadan sisteme giri\u015F yapamazs\u0131n\u0131z. Size yeni bir do\u011Frulama kodu g\u00F6nderdik.", _jsx("br", {}, void 0), _jsx("br", {}, void 0), "Sisteme kay\u0131tl\u0131 numaran\u0131z ", result.data.number, " \u015Feklindedir, e\u011Fer do\u011Frulama sms'i almad\u0131ysan\u0131z, numaran\u0131z\u0131 g\u00FCncelleyebilirsiniz. ", _jsx("a", __assign({ href: "#", onClick: function () {
                                setShowChangePhone(true);
                            }, className: "fw-bold" }, { children: "Cep Telefon Numaran\u0131z\u0131 G\u00FCncelleyin" }), void 0)] }, void 0), showChangePhone && _jsxs(_Fragment, { children: [" Telefon numaran\u0131z\u0131 giriniz, do\u011Frulanm\u0131\u015F bir telefon numaran\u0131z olmadan sisteme giri\u015F yapamazs\u0131n\u0131z. Yeni girdi\u011Finiz numaraya bir do\u011Frulama kodu g\u00F6nderilecektir. ", _jsx("br", {}, void 0), _jsx("br", {}, void 0), "Sisteme kay\u0131tl\u0131 numaran\u0131z ", result.data.number, " \u015Feklindedir "] }, void 0), !showChangePhone && _jsxs("div", __assign({ className: "row py-3" }, { children: [_jsx("div", __assign({ className: "col-7 pr-1" }, { children: _jsx(ReactInputMask, { mask: "9 9 9 9", className: "form-control", placeholder: "_ _ _ _", id: "id_mobileValidationCode" }, void 0) }), void 0), _jsx("div", __assign({ className: "col-5 pl-1" }, { children: _jsx(ResendValidationButton, { resendTimer: (180000), username: username, password: password }, void 0) }), void 0)] }), void 0), showChangePhone && _jsx("div", __assign({ className: "row py-3" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsx(ReactInputMask, { mask: "599 999 99 99", className: "form-control", placeholder: "5XXX XXX XX XX", id: "id_newMobile" }, void 0) }), void 0) }), void 0)] }), void 0) }, void 0));
}
var resendTimer = null;
var timer = null;
export function ResendValidationButton(prop) {
    var _a = useState(false), resendTimerButton = _a[0], setResendTimerButton = _a[1];
    if (resendTimer === null) {
        resendTimer = Number(prop.resendTimer);
    }
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        if (!resendTimerButton) {
            var timer_1 = setTimeout(function () {
                resendTimer = resendTimer - 1000;
                if (resendTimer <= 0) {
                    setResendTimerButton(true);
                    clearTimeout(timer_1);
                }
                forceUpdate();
            }, 1000);
        }
        return function () {
            clearTimeout(timer);
        };
    }, [resendTimer]);
    return _jsx(_Fragment, { children: _jsxs("button", __assign({ disabled: !resendTimerButton, onClick: function () {
                callLoginApi(prop.username, prop.password);
                resendTimer = Number(prop.resendTimer);
                setResendTimerButton(false);
                forceUpdate();
            }, className: "btn btn-warning w-100" }, { children: ["Tekrar G\u00F6nder ", resendTimer > 0 &&
                    _jsxs("span", { children: ["(", Math.floor(resendTimer / 60000) + ":" + ("00" + ((resendTimer / 1000) % 60)).slice(-2), ")"] }, void 0)] }), void 0) }, void 0);
}
export function renewPasswordApi(data) {
    return __awaiter(this, void 0, void 0, function () {
        var apiServices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiServices = new ApiService();
                    return [4 /*yield*/, apiServices
                            .start('post', env.core_api + "/member/api/password-reset/", data, true)
                            .then(function (response) {
                            return response;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function getNotificationCount(token) {
    return __awaiter(this, void 0, void 0, function () {
        var apiServices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiServices = new ApiService();
                    return [4 /*yield*/, apiServices
                            .start('post', "https://notification.oley.com/get_notification_summery/", { token: token }, true)
                            .then(function (response) {
                            return response;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
