var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Lock from "../../../assets/feather/lock.svg";
import { addToBetSlip } from "../../../services/betslip.services";
import _ from "lodash";
import { marketNameTransform } from "../../../services/sportsbook.services";
import { handicappedMarkets, listOfOddsContainingLongNames, listOfOddsContainingPlayerNames } from "../../../definitions/constants/market-categories.constant";
export function Odd(props) {
    // fixme: re-render edilmesede bu kısım sürekli çalıştırılıyor gibi kontrol et
    var market = props.market;
    var specialClass = '';
    if (market) {
        var event_1 = props.event;
        var marketOutcome = market.marketOutcome;
        if (event_1.eventType === '2') {
            marketOutcome = _.orderBy(market.marketOutcome.filter(function (el) { return el.fixedOddsWeb > 1; }), function (o) {
                return new Number(o.fixedOddsWeb);
            });
        }
        var col_1 = Math.ceil(12 / marketOutcome.length);
        if (col_1 === 2) {
            col_1 = 4;
        }
        else if (col_1 === 3) {
            col_1 = 6;
        }
        else if (col_1 === 1) {
            col_1 = 4;
        }
        if (event_1.eventType === '2' || event_1.sportSlug === 'motor-sporlar') {
            col_1 = 6;
        }
        if (listOfOddsContainingPlayerNames.includes("".concat(market.marketType, "-").concat(market.marketSubType))) {
            col_1 = 6;
        }
        if (listOfOddsContainingLongNames.includes("".concat(market.marketType, "-").concat(market.marketSubType))) {
            col_1 = 12;
            specialClass = 'px-1 mb-1';
        }
        return (_jsx(_Fragment, { children: marketOutcome.map(function (outcome, key) {
                return _jsxs("div", __assign({ className: "col-".concat(col_1, " ").concat(specialClass, " ") + (market.specialOddsValue && market.specialOddsValue !== '' ? ' specialOddsValue ' : '') }, { children: [!props.showOutcomeName ? _jsxs("div", __assign({ className: "outcome-name text-center " }, { children: [_jsx("span", __assign({ className: "label" }, { children: outcome.outcomeName }), void 0), handicappedMarkets.includes(market.marketType + '-' + market.marketSubType) || (market.marketName.includes("Maçın Geri Kalanını Kim Kazanır")) || (market.marketName.includes("Sıradaki Golü Kim Atar")) ? marketNameTransform(outcome.outcomeName, market.specialOddsValue, market) : " ".concat((market.specialOddsValue === '.5' ? '0.5' : market.specialOddsValue) || '')] }), void 0) : null, _jsxs("button", __assign({ className: "btn btn-odds ".concat(outcome.customOdds && 'custom-odds', " ").concat(outcome.isUpdated, " added-").concat(outcome.isSelected, " ") + (Number(event_1.bettingPhase) < 0 || market.marketStatus < 1 || market.marketStatus > 1 ? 'locked' : ''), onClick: function () {
                                addToBetSlip(event_1, market, outcome);
                                // console.log(market.marketType + '-' + market.marketSubType);
                            } }, { children: [props.showOutcomeName ? _jsx(_Fragment, { children: _jsx("div", __assign({ className: "outcome-name" }, { children: marketNameTransform(outcome.outcomeName, market.specialOddsValue, market, outcome) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ className: "ratio " + (props.showOutcomeName ? 'text-right' : '') }, { children: market.marketStatus < 0 ? _jsx(_Fragment, { children: _jsx("img", { src: Lock, className: "invert-color" }, void 0) }, void 0) : _jsx(_Fragment, { children: Number(outcome.fixedOddsWeb) > 1 && market.marketStatus !== 0 && market.marketStatus < 2 ? _jsxs(_Fragment, { children: [outcome.customOdds && _jsx("span", { children: Number(outcome.fixedOddsWeb).toFixed(2) }, void 0), Number(outcome.fixedOdds).toFixed(2)] }, void 0) : _jsx("div", { children: " - " }, void 0) }, void 0) }), void 0)] }), void 0)] }), "odds_" + event_1.eventId + market.marketId + '__' + key);
            }) }, void 0));
    }
    else {
        return _jsx(_Fragment, {}, void 0);
    }
}
